<template>
  <section id="bixolon-inner">
    <b-sidebar
      id="bixolon-sidebar"
      title="Bixolon"
      right
      backdrop
      shadow
    >
      <template #header="{ hide }">
        Bixolon
        <div class="header-left-block">
          <b-button
            v-if="is_active"
            variant="primary"
            class="cursor header-left-block__button"
            @click="turnOffIntegration"
          >
            Выключить интеграцию
          </b-button>
          <img
            class="close-sidebar cursor"
            src="/img/integrations/icon-sidebar-close.svg"
            alt=""
            @click="hide"
          />
        </div>
      </template>

      <template>
        <div class="sidebar-block info">
          <p class="sidebar-block__description">Подключите принтер по инструкции</p>
          <div class="info__detail">
            <img
              src="/img/integrations/icon-info-blue.svg"
              alt=""
            />
            <a target="_blank">Подробная справка</a>
            по подключению принтера
          </div>
        </div>
      </template>

      <template v-if="errorIntegration">
        <div class="error-block">
          <img
            src="/img/integrations/bixolon_attention.svg"
            alt=""
          />
          <p class="error-block__text">Ошибка</p>
          <p class="error-block__description">
            Программа интеграции для обмена данными не установлена,
            <a class="error-block__link">скачайте,</a> установите и попробуйте заново.
          </p>
        </div>
      </template>

      <template v-if="is_active">
        <div class="finish-intigration-block">
          <img
            src="/img/integrations/bixolon-ok.svg"
            alt=""
          />
          <p class="finish-intigration-block__text">Интеграция успешно подключена</p>
          <b-button
            variant="primary"
            class="cursor finish-intigration-block__button"
            @click="toDevices"
          >
            Перейти к списку принтеров
          </b-button>
        </div>
      </template>

      <template #footer="{ hide }">
        <div class="b-action-buttons">
          <b-button
            v-if="!is_active"
            variant="primary"
            class="cursor"
            @click="setIntegration()"
          >
            Продключить
          </b-button>
          <b-button
            v-if="!is_active"
            variant="outline-primary"
            class="cursor ml-3"
            @click="hide"
          >
            Отмена
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'BixolonSidebar',
    props: {
      is_active: Boolean,
      integration: {
        type: Object,
        default: () => {}
      }
    },

    data: () => ({
      errorIntegration: false
    }),

    computed: {
      ...mapGetters({
        getCurrentBranch: 'settings/getCurrentBranch',
        getUserInfo: 'core/getUserInfo'
      })
    },

    methods: {
      setIntegration() {
        this.$apollo
          .mutate({
            mutation: require('../gql/setIntegrationBixolon.gql'),
            variables: {
              input: {
                company: this.getUserInfo?.company?.id,
                branch: this.getCurrentBranch?.entities?.id,
                type: 'bixolon',
                tags: ['printer']
              }
            }
          })
          .then(() => {
            this.$emit('refetch')
            this.$noty.show(`Интеграция успешна`)
          })
          .catch((e) => {
            console.error('error: ', e)
            this.$noty.show(`Что-то пошло не так. Попробуйте еще раз`)
          })
      },

      turnOffIntegration() {
        this.$apollo
          .mutate({
            mutation: require('../gql/RemoveIntegrationBixolon.gql'),
            variables: {
              ids: [this.integration.id]
            }
          })
          .then(() => {
            this.$emit('refetch')
            this.$noty.show(`Интеграция выключена`)
          })
          .catch((e) => {
            console.error('error: ', e)
            this.$noty.show(`Что-то пошло не так. Попробуйте еще раз`)
          })
      },

      toDevices() {
        this.$router.push({
          name: 'devices.list',
          query: {
            tab: '2'
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    #bixolon-sidebar {
      width: 680px !important;
      background: #fff;

      .b-sidebar-header {
        position: relative;
        background: #fff;
        height: 85px;
      }

      .b-sidebar-footer {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 65px;
        display: flex;
      }

      .b-sidebar-body {
        background-color: #fff;
        padding: 0;
      }
    }
  }

  #bixolon-inner {
    #bixolon-sidebar {
      width: 680px !important;
      background: #fff;
      .b-sidebar-header {
        .header-left-block {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 22px;

          &__button {
            color: var(--text-primary-gray-700, #313131);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            border-radius: 2px;
            background: var(--gray-gray-50, #efefef);
            margin-right: 16px;
          }
        }
      }

      .b-sidebar-footer {
        .b-action-buttons {
          padding: 0 22px 0;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .b-sidebar-body {
        .sidebar-block {
          padding: 22px;
          margin-bottom: 22px;

          &__description {
            color: #000;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          }
        }

        .info {
          background-color: #fff;
          padding-bottom: 22px;
          font-size: 14px;
          line-height: 24px;
          color: #000000;

          &__detail {
            background: #e6f0ff;
            border-radius: 4px;
            margin-top: 22px;
            border-left: 3px solid #b9d6ff;
            padding: 15px;

            img {
              margin-right: 15px;
            }

            a {
              color: #313131;
              font-weight: 600;
              border-bottom: 1px solid #313131;
            }
          }
        }

        .error-block {
          width: 344px;
          margin: 158px auto 0 auto;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          &__text {
            color: var(--text-primary-gray-700, #313131);
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-top: 16px;
          }
          &__description {
            color: var(--text-primary-gray-700, #313131);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          &__link {
            color: var(--text-system-blue-400, #00a3ff);
            text-decoration-line: underline;
          }
        }

        .finish-intigration-block {
          width: 344px;
          margin: 255px auto 0 auto;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          &__text {
            color: var(--text-primary-gray-700, #313131);
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-top: 16px;
          }
          &__button {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            padding: 22px;
            margin-top: 12px;
          }
        }
      }
    }
  }
</style>
