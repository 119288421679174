var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-sidebar', {
    attrs: {
      "id": "evosections-sidebar",
      "title": "Секции и отделы",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    on: {
      "shown": _vm.setToken
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref) {
        var hide = _ref.hide;
        return [_vm._v(" Секции и отделы "), _c('img', {
          staticClass: "close-sidebar cursor",
          attrs: {
            "src": "/img/integrations/icon-sidebar-close.svg",
            "alt": ""
          },
          on: {
            "click": hide
          }
        })];
      }
    }])
  }, [_c('div', {
    staticClass: "sidebar-block info"
  }, [_c('p', [_vm._v(" Вы работаете на нескольких системах налогообложения? Или просто хотите видеть суммы продаж по разным отделам магазина? Установите приложение \"Секции и отделы\"! ")]), _c('div', {
    staticClass: "info__detail"
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/url_icon.svg",
      "alt": ""
    }
  }), _c('a', {
    attrs: {
      "href": "https://market.evotor.ru/store/apps/4c64a16f-c006-4a65-a4c6-eff23ac079e9",
      "target": "_blank"
    }
  }, [_vm._v("Перейти к приложению в маркете Эвотора")])])]), _c('div', {
    staticClass: "sidebar-block token"
  }, [_c('div', {
    staticClass: "text"
  }, [_vm._v(" Токен "), _c('img', {
    attrs: {
      "src": "/img/integrations/icon-info-gray.svg",
      "alt": ""
    }
  }), _c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.addToken.apply(null, arguments);
      }
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "Токен"
    },
    model: {
      value: _vm.token,
      callback: function ($$v) {
        _vm.token = $$v;
      },
      expression: "token"
    }
  })], 1)], 1), _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "primary",
      "disabled": _vm.connect_is_disabled
    },
    on: {
      "click": _vm.addToken
    }
  }, [_vm._v(" Проверить подключение ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }