<template>
  <b-sidebar
    id="evosections-sidebar"
    title="Секции и отделы"
    right
    backdrop
    shadow
    @shown="setToken"
  >
    <template #header="{ hide }">
      Секции и отделы
      <img
        class="close-sidebar cursor"
        src="/img/integrations/icon-sidebar-close.svg"
        alt=""
        @click="hide"
      />
    </template>

    <div class="sidebar-block info">
      <p>
        Вы работаете на нескольких системах налогообложения? Или просто хотите видеть суммы продаж по разным отделам
        магазина? Установите приложение "Секции и отделы"!
      </p>
      <div class="info__detail">
        <img
          src="/img/icons/url_icon.svg"
          alt=""
        />
        <a
          href="https://market.evotor.ru/store/apps/4c64a16f-c006-4a65-a4c6-eff23ac079e9"
          target="_blank"
          >Перейти к приложению в маркете Эвотора</a
        >
      </div>
    </div>
    <div class="sidebar-block token">
      <div class="text">
        Токен
        <img
          src="/img/integrations/icon-info-gray.svg"
          alt=""
        />
        <b-form @submit.prevent="addToken">
          <b-form-input
            v-model="token"
            type="text"
            placeholder="Токен"
          />
        </b-form>
      </div>
      <b-button
        variant="primary"
        class="mt-2"
        :disabled="connect_is_disabled"
        @click="addToken"
      >
        Проверить подключение
      </b-button>
    </div>
  </b-sidebar>
</template>

<script>
  import { mapGetters } from 'vuex'
  import * as R from 'ramda'

  export default {
    apollo: {
      Roles: {
        query: require('../gql/getRoles.gql'),
        result() {},
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        }
      },

      CashIntegrations: {
        query: require('../gql/getCashIntegrations.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.integrations = R.pathOr([], ['CashIntegrations'], data)
          this.isLoading = false
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
          this.isLoading = false
        }
      }
    },

    props: {
      integration: {
        type: Object,
        default: () => {}
      }
    },

    data: () => ({
      search: '',
      token: '',
      id: '',
      role_list: [],
      branch_list: [],
      role_mapping: [],
      branch_mapping: [],
      evotor_store_list: [],
      evotor_role_list: [],
      integrations: [],
      collapse_visible: false,
      isLoading: true
    }),

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      }),

      checkIntegration() {
        return {
          evosections: this.integrations.includes((el) => el.type === 'evosections')
        }
      },

      connect_is_disabled() {
        if (this.token.length > 0) return false
        return true
      }
    },

    methods: {
      submitHandlerSearch() {},

      setToken() {
        this.token = this.integration?.login ?? ''
      },

      setIntegration(type) {
        const data = this.integrations.find((el) => el.type === type)

        if (!data) return
        this.setSidebarData(data)
        this.collapse_visible = true
      },

      setSidebarData(data) {
        this.token = data.login
        this.id = data.id
        this.role_list = data.int_roles
        this.branch_list = data.int_branches
        this.evotor_role_list = data.ext_roles
        this.evotor_store_list = data.ext_branches
        this.role_mapping = data.role_mapping || [{ int_id: '', ext_id: '' }]
        this.branch_mapping = data.branch_mapping || [{ int_id: this.currentBranch.id, ext_id: '' }]
      },

      updateIntegration() {
        this.$apollo
          .mutate({
            mutation: require('../gql/UpdateCashIntegration.gql'),
            variables: {
              id: this.id,
              branch_mapping: this.branch_mapping,
              role_mapping: [] /* TODO this.role_mapping */
            }
          })
          .then(() => {
            this.$noty.show(`Интеграция успешна`)
            // this.collapse_visible = true
          })
          .catch((e) => {
            console.error('error: ', e)
            this.$noty.show(`Что-то пошло не так. Попробуйте еще раз`)
          })
      },

      addToken() {
        this.$apollo
          .mutate({
            mutation: require('../gql/InitCashIntegration.gql'),
            variables: {
              login: this.token,
              type: 'evosections',
              id: this.integration?.id ?? null
            }
          })
          .then(() => {
            this.$noty.show(`Интеграция успешно установлена`)
          })
          .catch((e) => {
            console.error('error: ', e)
            this.$noty.error(`Не удалось подключиться`)
          })
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    #evosections-sidebar {
      width: 680px;

      .b-sidebar-header {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 85px;
      }

      .b-sidebar-footer {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 65px;
        display: flex;

        .b-action-buttons {
          padding: 0 22px 0;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .b-sidebar-body {
        background: #fff;
        padding: 0;

        .sidebar-block {
          .block-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 16px;
            color: #313131;
            margin-bottom: 16px;
          }
        }

        .token {
          form {
            margin-top: 7px;

            input {
              height: 42px !important;
              border: 1px solid #bcbcbc;
              border-radius: 2px;
              font-size: 16px;
              color: #313131;
            }
          }
        }

        .b-select {
          border: 1px solid #e1e1e1;
          border-radius: 4px;

          &__title {
            border-bottom: 1px solid #e1e1e1;
            display: flex;

            .item {
              width: 50%;
              padding: 8px 0;
              text-align: center;
            }
          }

          &__item {
            display: flex;
            padding: 16px;
            justify-content: space-between;

            input,
            .ivu-select {
              width: 282px;
            }
          }
        }
      }

      // .token {
      //   form {
      //     margin-top: 7px;

      //     input {
      //       height: 42px !important;
      //       border: 1px solid #bcbcbc;
      //       border-radius: 2px;
      //       font-size: 16px;
      //       color: #313131;
      //     }
      //   }
      // }

      // .b-select {
      //   border: 1px solid #e1e1e1;
      //   border-radius: 4px;

      //   &__title {
      //     border-bottom: 1px solid #e1e1e1;
      //     display: flex;

      //     .item {
      //       width: 50%;
      //       padding: 8px 0;
      //       text-align: center;
      //     }
      //   }

      //   &__item {
      //     display: flex;
      //     padding: 16px;
      //     justify-content: space-between;

      //     input,
      //     .ivu-select {
      //       width: 282px;
      //     }
      //   }
      // }
    }
  }

  #evosections-sidebar {
    width: 680px;

    .b-sidebar-header {
      img.close-sidebar {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 22px;
      }
    }

    .b-sidebar-body {
      .sidebar-block {
        border-bottom: 1px solid #e1e1e1;
        padding: 22px;

        p {
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }

        a {
          border-bottom: 1px solid #0066ff;
          font-size: 16px;
        }
      }

      .info {
        font-size: 16px;
        line-height: 24px;
        color: #000000;

        &__detail {
          background: #e6f0ff;
          border-radius: 4px;
          margin-top: 22px;
          border-left: 3px solid #b9d6ff;
          padding: 15px;

          img {
            margin-right: 15px;
          }

          a {
            color: #313131;
            font-weight: 600;
            border-bottom: 1px solid #313131;
          }
        }
      }

      .token {
        .text {
          margin-top: 16px;

          img {
            margin-left: 5px;
          }
        }
      }

      .b-select {
        &__title {
          .item {
            &:nth-child(2) {
              border-left: 1px solid #e1e1e1;
            }
          }
        }
      }
    }
  }
</style>
