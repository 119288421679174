var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "b-api-item",
    on: {
      "click": _vm.on_click
    }
  }, [_c('div', {
    staticClass: "b-api-item__top"
  }, [_c('div', {
    staticClass: "logo-shadow"
  }, [_c('img', {
    attrs: {
      "src": _vm.data.img,
      "alt": ""
    }
  })]), _c('b-button', {
    style: _vm.data.is_active ? 'background-color: #00CB91; color: white; font-weight: bold' : ''
  }, [_vm.data.is_active ? _c('b-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "check",
      "scale": "1.2"
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.data.is_active ? 'Установлено' : 'Установить') + " ")], 1)], 1), _c('div', {
    staticClass: "b-api-item__content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.data.title))]), _c('p', [_vm._v(_vm._s(_vm.data.description))])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }