var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-2"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "step-info"
  }, [_vm._v(_vm._s(_vm.step))]), _c('div', {
    staticClass: "step-info-text"
  }, [_vm._v(_vm._s(_vm.title))])]), _c('div', {
    staticClass: "d-flex align-items-stretch mt-2"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center",
    staticStyle: {
      "width": "32px",
      "margin-right": "12px"
    }
  }, [!_vm.last ? _c('div', {
    staticClass: "sidebar-steps-stick"
  }) : _vm._e()]), _c('div', [_vm._t("default")], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }