<template>
  <section id="dibal-inner">
    <b-sidebar
      id="dibal-sidebar"
      title="ЕГАИС"
      right
      backdrop
      shadow
      @shown="setData"
    >
      <template #header="{ hide }">
        <div class="mr-auto">Dibal 525</div>
        <b-button
          v-if="is_active"
          style="margin-right: 12px"
          variant="gray-sidebar"
          @click="removeDeviceIntegration"
        >
          Выключить интеграцию
        </b-button>
        <img
          class="cursor"
          src="/img/integrations/icon-sidebar-close.svg"
          alt=""
          @click="hide"
        />
      </template>
      <b-overlay
        style="height: 100%"
        :show="false"
      >
        <div
          v-if="is_active"
          style="height: 100%"
        >
          <div
            class="d-flex justify-content-center align-items-center"
            style="height: 100%"
          >
            <b-button
              variant="primary"
              @click="toDevices"
            >
              К списку оборудования
            </b-button>
          </div>
        </div>
        <div v-else>
          <div class="sidebar-block info">
            <p>
              {{ currentStep.title }}
            </p>
            <div class="info__detail">
              <img
                src="/img/integrations/icon-info-blue.svg"
                alt=""
              />
              <a
                href="https://egais.center-inform.ru/tehpod/faq/Установка%20УТМ/"
                target="_blank"
                >Подробная справка</a
              >
              по интеграции
            </div>
          </div>
          <div v-if="currentPage === 1">
            <div class="sidebar-steps-page">
              <div class="sidebar-steps-title">Для добавления весов скачайте и установите Программу интеграции</div>
              <steps
                step="1"
                title="Скачайте Программу интеграции "
                style="margin-top: 32px"
              >
                <b-button
                  variant="outline-primary"
                  class="mb-4 mt-1"
                  @click="downloadAgent"
                >
                  Скачать Программу интеграции
                </b-button>
              </steps>
              <steps
                step="2"
                title="Установите Программу интеграции и авторизуйтесь"
              />
              <steps
                step="3"
                title="Выберите магазин"
                last
              >
                <i-select filterable>
                  <i-option
                    v-for="(el, index) in branches"
                    :key="index"
                    :value="el.id"
                  >
                    {{ el.name }}
                  </i-option>
                </i-select>
              </steps>
            </div>
          </div>
          <div v-if="currentPage === 2">
            <div class="sidebar-block image">
              <img
                :src="currentStep.img"
                alt=""
              />
            </div>
          </div>

          <div v-if="currentPage === 3">
            <div class="sidebar-block info">
              <steps
                step="8"
                title="Введите IP-адрес, который вы ввели на весах"
                style="margin-top: 32px"
              >
                <b-form-input
                  id="ip-input"
                  v-model="ip"
                  type="text"
                  placeholder="000.000.000.000"
                />
              </steps>
              <steps
                step="9"
                title="Установите Программу интеграции и авторизуйтесь"
                last
              >
                <b-button
                  variant="outline-primary"
                  class="mt-1"
                >
                  Проверить установку
                </b-button>
              </steps>
            </div>
          </div>
        </div>
      </b-overlay>
      <template #footer="{ hide }">
        <div class="b-action-buttons">
          <b-button
            variant="primary"
            class="cursor"
            :disabled="currentPage === 3 && !ip"
            @click="updateIntegration"
          >
            Продолжить
          </b-button>
          <b-button
            variant="outline-primary"
            class="cursor ml-3"
            @click="hide"
          >
            Отмена
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Steps from './Steps.vue'
  import Axios from 'axios'
  export default {
    name: 'Dibal',
    components: {
      Steps
    },
    data() {
      return {
        masks: {
          IP: {
            mask: 'B.B.B.B',
            tokens: {
              B: {
                pattern: /\b([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\b/
              }
            }
          }
        },
        ip: '',
        port: '1111',
        selectedBranch: '',
        selectedStorage: '',
        storage_list: [],
        stepIndex: 0,
        currentPage: 1,
        currentStep: {
          title:
            'Весы с печатью этикетки предназначены для этикетирования продуктов и товаров в различных сферах торговли и производства',
          img: '/img/integrations/dibal/1.svg'
        },
        steps: [
          {
            title:
              'Весы с печатью этикетки предназначены для этикетирования продуктов и товаров в различных сферах торговли и производства',
            img: '/img/integrations/dibal/2.svg'
          },
          {
            title:
              'Весы с печатью этикетки предназначены для этикетирования продуктов и товаров в различных сферах торговли и производства',
            img: '/img/integrations/dibal/3.svg'
          },
          {
            title:
              'Весы с печатью этикетки предназначены для этикетирования продуктов и товаров в различных сферах торговли и производства',
            img: '/img/integrations/dibal/4.svg'
          }
        ]
      }
    },
    apollo: {
      Storages: {
        query: require('../../../operational-processes/gql/getStorages.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch.id
          }
        },
        result({ data }) {
          this.storage_list = data?.Storages ?? []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },
    props: {
      is_active: Boolean,
      integration: Object,
      egais: {
        type: Object,
        default: () => {}
      }
    },
    async mounted() {},
    methods: {
      toDevices() {
        if (this.integration.devices?.length === 1) {
          this.$router.push({
            name: 'devices.list',
            query: {
              tab: '1'
            }
          })
        } else {
          this.$router.push({
            name: 'devices.list',
            query: {
              tab: '1'
            }
          })
        }
      },
      setUtm() {
        this.$apollo.mutate({
          mutation: require('../../gql/setEgaisUtm.graphql'),
          variables: {
            input: {
              storage: this.selectedStorage,
              branch: this.selectedBranch,
              host: ''
            }
          }
        })
      },
      updateIntegration() {
        if (this.currentPage === 1) {
          this.currentPage = 2
        }
        if (this.currentPage === 2 && this.stepIndex < this.steps.length - 1)
          this.currentStep = this.steps[++this.stepIndex]
        else if (this.currentPage === 2) this.currentPage = 3
        else if (this.currentPage === 3) this.update()
      },
      formatDate(date) {
        return new Intl.DateTimeFormat('ru-RU').format(new Date(date))
      },
      clearBranch() {
        this.selectedBranch = ''
      },
      setData() {
        if (!this.egais?.branch) {
          this.selectedBranch = this.currentBranch.id
        }
        if (!this.egais?.storage) {
          this.selectedStorage = this.storage_list?.[0]?.id
        }
      },
      downloadAgent() {
        Axios({
          url: 'https://s3.entersight.ru/agent/agent.msi',
          method: 'GET',
          responseType: 'blob'
        }).then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'agent.msi')
          document.body.appendChild(fileLink)

          fileLink.click()
        })
      },
      update() {
        this.$emit('update-integration', {
          devices: [
            {
              ip: this.ip,
              branch: this.currentBranch.id,
              type: 'dibal_500',
              tags: ['scales'],
              name: 'Dibal 525'
            }
          ],
          branch: this.currentBranch.id
        })
      },
      removeDeviceIntegration() {
        this.$emit('remove-device', this.integration)
      }
    },
    computed: {
      ...mapGetters({
        branches: 'settings/getBranchList',
        currentBranch: 'settings/getCurrentBranch'
      }),
      entities() {
        return this.branches.find((el) => el.id === this.selectedBranch)?.entities ?? []
      },
      inn_error() {
        const cert = this.certs?.find((el) => el.thumbprint === this.selectedCert)
        const entity = this.entities?.find((el) => el.id === this.selectedEntity)

        if (!cert || !entity) return true

        return !cert.subjectName.includes(entity.inn)
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .btn-gray-sidebar {
      background: #efefef;
      border-radius: 2px;
      color: #313131;
    }

    #dibal-inner {
      .sidebar-steps-page {
        padding: 22px;

        .sidebar-steps-stick {
          width: 2px;
          height: 100%;
          min-height: 16px;
          background: #00a3ff;
        }

        .step-info {
          border: 2px solid #00a3ff;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          color: #313131;
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 99px;
          margin-right: 12px;
        }
        .step-info-text {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;

          /* Text/primary - gray700 */

          color: #313131;
        }
      }
    }

    #dibal-sidebar {
      width: 680px !important;

      .b-sidebar-header {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 85px;

        img.close-sidebar {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 22px;
        }
      }

      .b-sidebar-footer {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 65px;
        display: flex;
      }

      .b-sidebar-body {
        background: #fff;
        padding: 0;

        .block-title {
          font-weight: 600;
          font-size: 18px;
          line-height: 16px;
          color: #313131;
          margin-bottom: 16px;
        }
      }

      .token {
        .text {
          margin-top: 16px;
        }

        form {
          margin-top: 7px;

          input {
            height: 42px !important;
            border: 1px solid #bcbcbc;
            border-radius: 2px;
            font-size: 16px;
            color: #313131;
          }
        }
      }

      .b-select {
        border: 1px solid #e1e1e1;
        border-radius: 4px;

        &__title {
          border-bottom: 1px solid #e1e1e1;
          display: flex;

          .item {
            width: 50%;
            padding: 8px 0;
            text-align: center;
          }
        }

        &__item {
          display: flex;
          padding: 16px;
          justify-content: space-between;

          input,
          .ivu-select {
            width: 282px;
          }
        }
      }
    }
  }

  #dibal-inner {
    .sidebar-steps-page {
      padding: 22px;
    }
    .sidebar-steps-title {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 16px;
      /* identical to box height, or 89% */

      display: flex;
      align-items: center;

      /* Text/primary - gray700 */

      color: #313131;
    }
    #dibal-sidebar {
      width: 680px !important;

      .b-sidebar-footer {
        .b-action-buttons {
          padding: 0 22px 0;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .b-sidebar-body {
        .sidebar-block {
          border-bottom: 1px solid #e1e1e1;
          padding: 22px;

          p {
            font-size: 16px;
            line-height: 24px;
            color: #000000;
          }

          a {
            border-bottom: 1px solid #0066ff;
            font-size: 16px;
          }
        }

        .info {
          font-size: 16px;
          line-height: 24px;
          color: #000000;

          &__detail {
            background: #e6f0ff;
            border-radius: 4px;
            margin-top: 22px;
            border-left: 3px solid #b9d6ff;
            padding: 15px;

            img {
              margin-right: 15px;
            }

            a {
              color: #313131;
              font-weight: 600;
              border-bottom: 1px solid #313131;
            }
          }
        }

        .image {
          box-sizing: border-box;

          /* Auto layout */

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 22px;
          padding: 22px;

          background: #ffffff;
          /* Gray/gray150 */

          border: 1px solid #e1e1e1;
          border-radius: 4px;
        }
        .token {
          .text {
            img {
              margin-left: 5px;
            }
          }
        }

        .b-select {
          &__title {
            .item {
              &:nth-child(2) {
                border-left: 1px solid #e1e1e1;
              }
            }
          }
        }
      }
    }
  }
</style>
