var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "shtrix-m-inner"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "shtrih_m-sidebar",
      "title": "ЕГАИС",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    on: {
      "shown": _vm.setData
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref) {
        var hide = _ref.hide;
        return [_c('div', {
          staticClass: "mr-auto"
        }, [_vm._v("Штрих М 15-2.5")]), _vm.is_active ? _c('b-button', {
          staticStyle: {
            "margin-right": "12px"
          },
          attrs: {
            "variant": "gray-sidebar"
          },
          on: {
            "click": _vm.removeDeviceIntegration
          }
        }, [_vm._v(" Выключить интеграцию ")]) : _vm._e(), _c('img', {
          staticClass: "cursor",
          attrs: {
            "src": "/img/integrations/icon-sidebar-close.svg",
            "alt": ""
          },
          on: {
            "click": hide
          }
        })];
      }
    }, {
      key: "footer",
      fn: function (_ref2) {
        var hide = _ref2.hide;
        return [_c('div', {
          staticClass: "b-action-buttons"
        }, [_c('b-button', {
          staticClass: "cursor",
          attrs: {
            "variant": "primary",
            "disabled": _vm.currentPage === 2 && !_vm.ip
          },
          on: {
            "click": _vm.updateIntegration
          }
        }, [_vm._v(" Продолжить ")]), _c('b-button', {
          staticClass: "cursor ml-3",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": hide
          }
        }, [_vm._v(" Отмена ")])], 1)];
      }
    }])
  }, [_c('b-overlay', {
    staticStyle: {
      "height": "100%"
    },
    attrs: {
      "show": false
    }
  }, [_vm.is_active ? _c('div', {
    staticStyle: {
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-center align-items-center",
    staticStyle: {
      "height": "100%"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.toDevices
    }
  }, [_vm._v(" К списку оборудования ")])], 1)]) : _c('div', [_vm.currentPage === 1 ? _c('div', [_c('div', {
    staticClass: "sidebar-block info"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.currentStep.title) + " ")]), _c('div', {
    staticClass: "info__detail"
  }, [_c('img', {
    attrs: {
      "src": "/img/integrations/icon-info-blue.svg",
      "alt": ""
    }
  }), _c('a', {
    attrs: {
      "href": "https://egais.center-inform.ru/tehpod/faq/Установка%20УТМ/",
      "target": "_blank"
    }
  }, [_vm._v("Подробная справка")]), _vm._v(" по интеграции ")])]), _c('div', {
    staticClass: "sidebar-block image"
  }, [_c('img', {
    attrs: {
      "src": _vm.currentStep.img,
      "alt": ""
    }
  })])]) : _vm._e(), _vm.currentPage === 2 ? _c('div', [_c('div', {
    staticClass: "sidebar-steps-page"
  }, [_c('div', {
    staticClass: "sidebar-steps-title"
  }, [_vm._v("Для добавления весов скачайте и установите Программу интеграции")]), _c('steps', {
    staticStyle: {
      "margin-top": "32px"
    },
    attrs: {
      "step": "1",
      "title": "Скачайте Программу интеграции "
    }
  }, [_c('b-button', {
    staticClass: "mb-4 mt-1",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.downloadAgent
    }
  }, [_vm._v(" Скачать Программу интеграции ")])], 1), _c('steps', {
    attrs: {
      "step": "2",
      "title": "Установите Программу интеграции и авторизуйтесь"
    }
  }), _c('steps', {
    attrs: {
      "step": "3",
      "title": "Введите IP-адрес, который вы сфотографировали ранее"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', [_c('label', {
    attrs: {
      "for": "ip-input"
    }
  }, [_vm._v("Ip")]), _c('b-form-input', {
    attrs: {
      "id": "ip-input",
      "type": "text",
      "placeholder": "000.000.000.000"
    },
    model: {
      value: _vm.ip,
      callback: function ($$v) {
        _vm.ip = $$v;
      },
      expression: "ip"
    }
  })], 1), _c('div', {
    staticStyle: {
      "margin-left": "14px"
    }
  }, [_c('label', {
    attrs: {
      "for": "port-input"
    }
  }, [_vm._v("Порт")]), _c('b-form-input', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '####',
      expression: "'####'"
    }],
    staticStyle: {
      "width": "70px"
    },
    attrs: {
      "id": "port-input",
      "type": "text",
      "placeholder": "Порт"
    },
    model: {
      value: _vm.port,
      callback: function ($$v) {
        _vm.port = $$v;
      },
      expression: "port"
    }
  })], 1)])]), _c('steps', {
    attrs: {
      "step": "4",
      "title": "Проверьте корректность установки",
      "last": ""
    }
  }, [_c('b-button', {
    staticClass: "mt-1",
    attrs: {
      "variant": "outline-primary"
    }
  }, [_vm._v(" Проверить установку ")])], 1)], 1)]) : _vm._e()])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }