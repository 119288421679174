<template>
  <section id="egais-inner">
    <b-sidebar
      id="egais-sidebar"
      title="ЕГАИС"
      right
      backdrop
      shadow
      @shown="setData"
    >
      <template #header="{ hide }">
        ЕГАИС
        <img
          class="close-sidebar cursor"
          src="/img/integrations/icon-sidebar-close.svg"
          alt=""
          @click="hide"
        />
      </template>

      <div class="sidebar-block info">
        <p>
          Единая государственная автоматизированная информационная система учета объема производства и оборота этилового
          спирта, алкогольной и спиртосодержащей продукции.
        </p>
        <div class="info__detail">
          <img
            src="/img/integrations/icon-info-blue.svg"
            alt=""
          />
          <a
            href="https://egais.center-inform.ru/tehpod/faq/Установка%20УТМ/"
            target="_blank"
            >Подробная справка</a
          >
          по интеграции
        </div>
      </div>
      <div
        class="sidebar-block token"
        style="border-bottom: 0"
      >
        <div class="info-text">Для подключения скачайте и установите Программу интеграции</div>
        <steps
          step="1"
          title="Скачайте Программу интеграции "
          style="margin-top: 32px"
        >
          <b-button
            variant="outline-primary"
            class="mb-4 mt-1"
            @click="downloadAgent"
          >
            Скачать Программу интеграции
          </b-button>
        </steps>
        <steps
          step="2"
          title="Установите Программу интеграции и авторизуйтесь"
        />
        <div class="address">
          <steps
            step="3"
            title="Адрес модуля UTM"
            class="ip"
          >
            <b-form-input
              id="utm-address"
              v-model="host"
              style="width: 283px; margin-right: 16px"
              placeholder="Пример: 192.168.10.12"
              class="mb-3"
            />
          </steps>
          <div class="port">
            <p>Порт модуля UTM</p>
            <b-form-input
              id="utm-address"
              v-model="port"
              style="width: 160px"
              placeholder="Порт"
              class="mb-3"
            />
          </div>
        </div>

        <steps
          step="5"
          title="Филиал в Энтерсайте"
        >
          <i-select
            id="branch-picker"
            v-model="selectedBranch"
            style="width: 283px"
            :label-in-value="true"
          >
            <i-option
              v-for="option of branches"
              :key="option.id"
              style="max-width: 630px"
              :value="option.id"
            >
              {{ option.name }}
            </i-option>
          </i-select>
        </steps>
        <steps
          step="6"
          title="Место хранения регистрации документов"
        >
          <i-select
            id="storage-picker"
            v-model="selectedStorage"
            class="mb-2"
            style="width: 283px"
          >
            <i-option
              v-for="item in storage_list"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </i-option>
          </i-select>
        </steps>
        <steps
            step="7"
            title="Юр.лицо"
            last
        >
          <i-select
              id="storage-picker"
              v-model="selectedEntity"
              class="mb-2"
              style="width: 283px"
          >
            <i-option
                v-for="item in entity_list"
                :key="item.id"
                :value="item.id"
            >
              {{ item.name }}
            </i-option>
          </i-select>
        </steps>
      </div>
      <template #footer="{ hide }">
        <div class="b-action-buttons">
          <b-button
            variant="primary"
            class="cursor"
            @click="updateIntegration(hide)"
          >
            Подключить
          </b-button>
          <b-button
            variant="outline-primary"
            class="cursor ml-3"
            @click="hide"
          >
            Отмена
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Axios from 'axios'
  import Steps from '@/views/integrations/components/shtrix-m/Steps.vue'

  export default {
    name: 'Egais',
    components: {
      Steps
    },
    data() {
      return {
        selectedBranch: this.egais?.branch,
        selectedStorage: this.egais?.storage,
        selectedEntity: this.egais?.entity,
        storage_list: [],
        entity_list: [],
        host: '',
        port: ''
      }
    },
    apollo: {
      Storages: {
        query: require('../../operational-processes/gql/getStorages.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch.id
          }
        },
        result({ data }) {
          this.storage_list = data?.Storages ?? []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },
      Entities: {
        query: require('../../settings/gql/CompanyEntities.graphql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.entity_list = data?.Entities ?? []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },
    props: {
      egais: {
        type: Object,
        default: () => {}
      }
    },
    watch: {
      egais(val) {
        this.selectedBranch = val.branch
        this.selectedStorage = val.storage
        this.selectedEntity = val.entity
        if (val?.host) {
          const splitArray = val.host.split('http://')
          if (splitArray[1]) {
            const [ip, port] = splitArray[1].split(':')
            this.host = ip
            this.port = port
          }
        }
      }
    },
    async mounted() {
      if (this?.egais?.host) {
        const splitArray = this?.egais?.host.split('http://')
        if (splitArray[1]) {
          const [ip, port] = splitArray[1].split(':')
          this.host = ip
          this.port = port
        }
      }
    },
    methods: {
      async setUtm() {
        await this.$apollo.mutate({
          mutation: require('../gql/setEgaisUtm.graphql'),
          variables: {
            input: {
              id: this.egais?.id,
              storage: this.selectedStorage,
              entity: this.selectedEntity,
              branch: this.currentBranch.id,
              host: `http://${this.host}:${this.port}`
            }
          }
        })
      },
      async updateIntegration(hide) {
        await this.setUtm()
        setTimeout(() => this.$emit('refetch'), 100)
        hide()
        this.$noty.show('Интеграция успешно подключена')

        // this.$emit('update-integration', {
        //   thumbprint: this.selectedCert,
        //   branch: this.selectedBranch,
        //   entity: this.selectedEntity
        // })
      },
      formatDate(date) {
        return new Intl.DateTimeFormat('ru-RU').format(new Date(date))
      },
      clearBranch() {
        this.selectedBranch = ''
      },
      setData() {
        if (!this.egais?.branch) {
          this.selectedBranch = this.currentBranch.id
        }
        if (!this.egais?.storage) {
          this.selectedStorage = this.storage_list?.[0]?.id
        }
      },
      downloadAgent() {
        Axios({
          url: 'https://s3.entersight.ru/agent/agent.msi',
          method: 'GET',
          responseType: 'blob'
        }).then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'agent.msi')
          document.body.appendChild(fileLink)

          fileLink.click()
        })
      }
    },
    computed: {
      ...mapGetters({
        branches: 'settings/getBranchList',
        currentBranch: 'settings/getCurrentBranch'
      }),
      entities() {
        return this.branches.find((el) => el.id === this.selectedBranch)?.entities ?? []
      },
      inn_error() {
        const cert = this.certs?.find((el) => el.thumbprint === this.selectedCert)
        const entity = this.entities?.find((el) => el.id === this.selectedEntity)

        if (!cert || !entity) return true

        return !cert.subjectName.includes(entity.inn)
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .address {
      .ip {
      }
    }

    #egais-sidebar {
      width: 680px !important;

      .b-sidebar-header {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 85px;
      }

      .b-sidebar-footer {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 65px;
        display: flex;
      }

      .b-sidebar-body {
        background: #fff;
        padding: 0;

        .sidebar-block {
          .block-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 16px;
            color: #313131;
            margin-bottom: 16px;
          }
        }

        .token {
          .text {
            margin-top: 16px;
          }

          form {
            margin-top: 7px;

            input {
              height: 42px !important;
              border: 1px solid #bcbcbc;
              border-radius: 2px;
              font-size: 16px;
              color: #313131;
            }
          }
        }

        .b-select {
          border: 1px solid #e1e1e1;
          border-radius: 4px;

          &__title {
            border-bottom: 1px solid #e1e1e1;
            display: flex;

            .item {
              width: 50%;
              padding: 8px 0;
              text-align: center;
            }
          }

          &__item {
            display: flex;
            padding: 16px;
            justify-content: space-between;

            input,
            .ivu-select {
              width: 282px;
            }
          }
        }
      }
    }
  }

  .address {
    display: flex;

    .port {
      p {
        margin: 0;
        height: 32px;
        align-items: center;
        display: flex;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 18px !important;
        line-height: 21px !important;
        color: #313131 !important;
        margin-bottom: 8px;
      }
    }
  }
  #egais-inner {
    #egais-sidebar {
      width: 680px !important;

      .b-sidebar-header {
        img.close-sidebar {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 22px;
        }
      }

      .b-sidebar-footer {
        .b-action-buttons {
          padding: 0 22px 0;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .b-sidebar-body {
        background: #fff;
        padding: 0;

        .sidebar-block {
          border-bottom: 1px solid #e1e1e1;
          padding: 22px;

          p {
            font-size: 16px;
            line-height: 24px;
            color: #000000;
          }

          a {
            border-bottom: 1px solid #0066ff;
            font-size: 16px;
          }
        }

        .info {
          font-size: 16px;
          line-height: 24px;
          color: #000000;

          &__detail {
            background: #e6f0ff;
            border-radius: 4px;
            margin-top: 22px;
            border-left: 3px solid #b9d6ff;
            padding: 15px;

            img {
              margin-right: 15px;
            }

            a {
              color: #313131;
              font-weight: 600;
              border-bottom: 1px solid #313131;
            }
          }
        }

        .token {
          .text {
            img {
              margin-left: 5px;
            }
          }
        }

        .b-select {
          &__title {
            .item {
              &:nth-child(2) {
                border-left: 1px solid #e1e1e1;
              }
            }
          }

          &__item {
            display: flex;
            padding: 16px;
            justify-content: space-between;

            input,
            .ivu-select {
              width: 282px;
            }
          }
        }
      }
    }

    .info-text {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 16px;
    }
  }
</style>
