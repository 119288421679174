<template>
  <section id="fasol-inner">
    <b-sidebar
      id="fasol-sidebar"
      title="СБИС ЭДО"
      right
      backdrop
      shadow
    >
      <template #header="{ hide }">
        <h2 class="head-title">Фасоль</h2>
        <img
          class="close-sidebar cursor"
          src="/img/integrations/icon-sidebar-close.svg"
          alt=""
          @click="hide"
        />
      </template>

      <div class="sidebar-block info">
        <p>
          Программа "Фасоль" от компании METRO позволяет получить множество преимуществ для бизнеса: от современного
          оформления, планировки магазина и готовых решений по оборудованию и IT до рекомендаций по мерчандайзингу.
        </p>
        <div class="info__detail">
          <img
            src="/img/integrations/icon-info-blue.svg"
            alt=""
          />
          <router-link to="#">Подробная справка</router-link>
          по интеграции
        </div>
      </div>
      <div
        v-if="!categories.length && has_fasol"
        class="sidebar-block"
      >
        <b-overlay
          :show="loading"
          spinner-small
        >
          <div class="category-download">
            У вас пустой каталог товаров <span @click="createFranchiseClassif">Добавить каталог товаров «Фасоль»</span>
          </div>
        </b-overlay>
      </div>
      <div class="sidebar-block token">
        <div
          v-for="branch of branch_list"
          :key="branch.id"
          class="branch-item"
        >
          <div class="branch-item-info">
            <div class="branch-header">{{ branch.name }}</div>
            <e-switch
              :value="branch.franchise === 'fasol' ? 'all' : 'off'"
              @change="(val) => setBranch(branch.id, val)"
            />
          </div>
          <div
            v-if="branch.franchise === 'fasol'"
            class="branch-id"
          >
            {{ 'ID: ' + branch.id }}
            <svg
              class="branch-id-copy"
              width="16"
              height="18"
              viewBox="0 0 16 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="copy(branch.id)"
            >
              <path
                d="M9.93359 18H3.21875C1.66789 18 0.40625 16.7384 0.40625 15.1875V5.66016C0.40625 4.1093 1.66789 2.84766 3.21875 2.84766H9.93359C11.4845 2.84766 12.7461 4.1093 12.7461 5.66016V15.1875C12.7461 16.7384 11.4845 18 9.93359 18ZM3.21875 4.25391C2.44339 4.25391 1.8125 4.8848 1.8125 5.66016V15.1875C1.8125 15.9629 2.44339 16.5938 3.21875 16.5938H9.93359C10.709 16.5938 11.3398 15.9629 11.3398 15.1875V5.66016C11.3398 4.8848 10.709 4.25391 9.93359 4.25391H3.21875ZM15.5586 13.4297V2.8125C15.5586 1.26164 14.297 0 12.7461 0H4.94141C4.55304 0 4.23828 0.314758 4.23828 0.703125C4.23828 1.09149 4.55304 1.40625 4.94141 1.40625H12.7461C13.5215 1.40625 14.1523 2.03714 14.1523 2.8125V13.4297C14.1523 13.8181 14.4671 14.1328 14.8555 14.1328C15.2438 14.1328 15.5586 13.8181 15.5586 13.4297Z"
                fill="#E5E5E5"
              />
            </svg>
          </div>
        </div>
      </div>

      <template #footer="{ hide }">
        <div class="b-action-buttons">
          <b-button
            variant="primary"
            class="cursor"
            @click="updateIntegration(hide)"
          >
            {{ 'Закрыть' }}
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
  import { mapActions, mapMutations, mapGetters } from 'vuex'
  import ESwitch from '@/views/settings/components/access_rights/ESwitch.vue'

  export default {
    name: 'FasolSidebar',
    components: { ESwitch },
    apollo: {
      Branches: {
        fetchPolicy: 'no-cache',
        query: require('../gql/getBranches.gql'),
        result({ data }) {
          this.branch_list = data?.Branches.sort((a, b) => (a.name > b.name ? 1 : -1)) ?? []
          this.SET_BRANCHES(this.branch_list)

          if (this.currentBranch?.__typename === 'BranchClassifModel') return
          else {
            const newDataCurrentBranch = this.branch_list.find((el) => el.id === this.currentBranch.id)
            this.SET_CURRENT_BRANCH(newDataCurrentBranch)
          }
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        }
      },
      ReadCategoryByPath: {
        fetchPolicy: 'no-cache',
        query: require('../gql/getCategoryPath.graphql'),
        result({ data }) {
          this.categories = data.ReadCategoryByPath || []
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        }
      }
    },
    props: {
      is_active: Boolean,
      data_sbis: {
        type: Object
      },
      integration: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        entities: [],
        categories: [],
        loading: false,
        login: '',
        selectedCert: [],
        password: '',
        entity: [],
        entityId: '',
        certs: [],
        branch_list: [],
        thumbprint: ''
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      }),
      has_fasol() {
        return this.branch_list?.some((el) => el.franchise === 'fasol')
      }
    },
    methods: {
      ...mapActions({
        getBranchList: 'settings/getBranches'
      }),
      ...mapMutations({
        SET_CURRENT_BRANCH: 'settings/SET_CURRENT_BRANCH',
        SET_BRANCHES: 'settings/SET_BRANCHES'
      }),
      async createFranchiseClassif() {
        this.loading = true
        try {
          await this.$apollo.mutate({
            mutation: require('../gql/CreateFranchiseClassif.graphql'),
            variables: {
              franchise: 'fasol'
            }
          })
          this.categories = [1]
          this.$noty.show('Успешно')
        } catch (e) {
          console.error(e)
          this.$noty.error(
            e.graphQLErrors?.[0]?.message === 'CLASSIF_NOT_EMPTY'
              ? 'Ошибка: у вас уже заполненный классификатор'
              : 'Ошибка'
          )
        }

        this.loading = false
      },
      async setBranch(id, value) {
        await this.$apollo.mutate({
          mutation: require('../gql/UpdateBranch.gql'),
          variables: {
            input: {
              id: id,
              franchise: value === 'off' ? '' : 'fasol'
            }
          }
        })
        await this.$apollo.queries.Branches.refetch()
      },
      async copy(info) {
        await navigator.clipboard.writeText(info)
        this.$noty.show(`Скопированно`)
      },
      updateIntegration(hide) {
        this.$emit('refetch')
        hide()
      },

      formatDate(date) {
        return new Intl.DateTimeFormat('ru-RU').format(new Date(date))
      },
      clearEntity() {
        this.selectedEntity = ''
      },
      clearBranch() {
        this.selectedBranch = ''
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .multiselect {
      margin-top: 22px;
      //height: 65px;
    }

    #fasol-sidebar {
      width: 680px !important;

      .spinner-border {
        margin-top: 0px;
      }

      .b-sidebar-header {
        position: relative;
        background: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        height: 85px;
      }

      .b-sidebar-footer {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 65px;
        display: flex;
      }

      .b-sidebar-body {
        background: #fff;
        padding: 0;

        .sidebar-block {
          .block-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 16px;
            color: #313131;
            margin-bottom: 16px;
          }

          a {
            border-bottom: 1px solid #0066ff;
            font-size: 16px;
          }
        }

        .info {
          &__detail {
            a {
              color: #313131;
              font-weight: 600;
              border-bottom: 1px solid #313131;
            }
          }
        }

        .token {
          .input {
            height: 42px !important;
            border: 1px solid #bcbcbc;
            border-radius: 2px !important;
            color: #313131;
            padding: 0px 12px 0px 10px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px !important;
            line-height: 14px !important;
          }

          .text {
            margin-top: 22px;
            margin-bottom: 6px;
          }

          form {
            margin-top: 7px;
          }
        }

        .b-select {
          border: 1px solid #e1e1e1;
          border-radius: 4px;

          &__title {
            border-bottom: 1px solid #e1e1e1;
            display: flex;

            .item {
              width: 50%;
              padding: 8px 0;
              text-align: center;
            }
          }

          &__item {
            display: flex;
            padding: 16px;
            justify-content: space-between;

            input,
            .ivu-select {
              width: 282px;
            }
          }
        }
      }
    }
  }

  #fasol-inner {
    #fasol-sidebar {
      width: 680px !important;
      .category-download {
        background: #fffae7;
        border-left: 3px solid #ffa800;
        border-radius: 4px;
        padding: 16px;
        span {
          color: #ffa800;
          cursor: pointer;
          text-decoration-line: underline;
        }
      }
      .branch-item {
        border: 1px solid #e1e1e1;
        border-radius: 6px;
        padding: 16px;
        margin-bottom: 30px;

        .branch-item-info {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        .branch-header {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #000000;
        }

        .branch-id {
          margin-top: 8px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #888888;
          align-items: center;
          display: flex;
          .branch-id-copy {
            margin-left: 8px;
            cursor: pointer;
          }
        }
      }

      .b-sidebar-header {
        .head-title {
          font-family: 'Gilroy-Medium', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 28px;
          line-height: 33px;
          color: #000000;
        }

        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

        img.close-sidebar {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 22px;
        }
      }

      .b-sidebar-footer {
        .b-action-buttons {
          padding: 0 22px 0;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .b-sidebar-body {
        .sidebar-block {
          //border-bottom: 1px solid #e1e1e1;
          padding: 22px;

          p {
            font-size: 16px;
            line-height: 24px;
            color: #000000;
          }
        }

        .info {
          font-size: 16px;
          line-height: 24px;
          color: #000000;

          &__detail {
            background: #e6f0ff;
            border-radius: 4px;
            margin-top: 22px;
            border-left: 3px solid #b9d6ff;
            padding: 15px;

            img {
              margin-right: 15px;
            }
          }
        }

        .token {
          .input {
            &::placeholder {
              color: #bcbcbc;
            }
          }

          .text {
            img {
              margin-left: 5px;
            }
          }
        }

        .b-select {
          &__title {
            .item {
              &:nth-child(2) {
                border-left: 1px solid #e1e1e1;
              }
            }
          }
        }
      }
    }
  }
</style>
