import { render, staticRenderFns } from "./DKlink.vue?vue&type=template&id=1062cffe&scoped=true&"
import script from "./DKlink.vue?vue&type=script&lang=js&"
export * from "./DKlink.vue?vue&type=script&lang=js&"
import style0 from "./DKlink.vue?vue&type=style&index=0&id=1062cffe&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1062cffe",
  null
  
)

export default component.exports