var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "egais-inner"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "egais-sidebar",
      "title": "ЕГАИС",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    on: {
      "shown": _vm.setData
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref) {
        var hide = _ref.hide;
        return [_vm._v(" ЕГАИС "), _c('img', {
          staticClass: "close-sidebar cursor",
          attrs: {
            "src": "/img/integrations/icon-sidebar-close.svg",
            "alt": ""
          },
          on: {
            "click": hide
          }
        })];
      }
    }, {
      key: "footer",
      fn: function (_ref2) {
        var hide = _ref2.hide;
        return [_c('div', {
          staticClass: "b-action-buttons"
        }, [_c('b-button', {
          staticClass: "cursor",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.updateIntegration(hide);
            }
          }
        }, [_vm._v(" Подключить ")]), _c('b-button', {
          staticClass: "cursor ml-3",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": hide
          }
        }, [_vm._v(" Отмена ")])], 1)];
      }
    }])
  }, [_c('div', {
    staticClass: "sidebar-block info"
  }, [_c('p', [_vm._v(" Единая государственная автоматизированная информационная система учета объема производства и оборота этилового спирта, алкогольной и спиртосодержащей продукции. ")]), _c('div', {
    staticClass: "info__detail"
  }, [_c('img', {
    attrs: {
      "src": "/img/integrations/icon-info-blue.svg",
      "alt": ""
    }
  }), _c('a', {
    attrs: {
      "href": "https://egais.center-inform.ru/tehpod/faq/Установка%20УТМ/",
      "target": "_blank"
    }
  }, [_vm._v("Подробная справка")]), _vm._v(" по интеграции ")])]), _c('div', {
    staticClass: "sidebar-block token",
    staticStyle: {
      "border-bottom": "0"
    }
  }, [_c('div', {
    staticClass: "info-text"
  }, [_vm._v("Для подключения скачайте и установите Программу интеграции")]), _c('steps', {
    staticStyle: {
      "margin-top": "32px"
    },
    attrs: {
      "step": "1",
      "title": "Скачайте Программу интеграции "
    }
  }, [_c('b-button', {
    staticClass: "mb-4 mt-1",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.downloadAgent
    }
  }, [_vm._v(" Скачать Программу интеграции ")])], 1), _c('steps', {
    attrs: {
      "step": "2",
      "title": "Установите Программу интеграции и авторизуйтесь"
    }
  }), _c('div', {
    staticClass: "address"
  }, [_c('steps', {
    staticClass: "ip",
    attrs: {
      "step": "3",
      "title": "Адрес модуля UTM"
    }
  }, [_c('b-form-input', {
    staticClass: "mb-3",
    staticStyle: {
      "width": "283px",
      "margin-right": "16px"
    },
    attrs: {
      "id": "utm-address",
      "placeholder": "Пример: 192.168.10.12"
    },
    model: {
      value: _vm.host,
      callback: function ($$v) {
        _vm.host = $$v;
      },
      expression: "host"
    }
  })], 1), _c('div', {
    staticClass: "port"
  }, [_c('p', [_vm._v("Порт модуля UTM")]), _c('b-form-input', {
    staticClass: "mb-3",
    staticStyle: {
      "width": "160px"
    },
    attrs: {
      "id": "utm-address",
      "placeholder": "Порт"
    },
    model: {
      value: _vm.port,
      callback: function ($$v) {
        _vm.port = $$v;
      },
      expression: "port"
    }
  })], 1)], 1), _c('steps', {
    attrs: {
      "step": "5",
      "title": "Филиал в Энтерсайте"
    }
  }, [_c('i-select', {
    staticStyle: {
      "width": "283px"
    },
    attrs: {
      "id": "branch-picker",
      "label-in-value": true
    },
    model: {
      value: _vm.selectedBranch,
      callback: function ($$v) {
        _vm.selectedBranch = $$v;
      },
      expression: "selectedBranch"
    }
  }, _vm._l(_vm.branches, function (option) {
    return _c('i-option', {
      key: option.id,
      staticStyle: {
        "max-width": "630px"
      },
      attrs: {
        "value": option.id
      }
    }, [_vm._v(" " + _vm._s(option.name) + " ")]);
  }), 1)], 1), _c('steps', {
    attrs: {
      "step": "6",
      "title": "Место хранения регистрации документов"
    }
  }, [_c('i-select', {
    staticClass: "mb-2",
    staticStyle: {
      "width": "283px"
    },
    attrs: {
      "id": "storage-picker"
    },
    model: {
      value: _vm.selectedStorage,
      callback: function ($$v) {
        _vm.selectedStorage = $$v;
      },
      expression: "selectedStorage"
    }
  }, _vm._l(_vm.storage_list, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c('steps', {
    attrs: {
      "step": "7",
      "title": "Юр.лицо",
      "last": ""
    }
  }, [_c('i-select', {
    staticClass: "mb-2",
    staticStyle: {
      "width": "283px"
    },
    attrs: {
      "id": "storage-picker"
    },
    model: {
      value: _vm.selectedEntity,
      callback: function ($$v) {
        _vm.selectedEntity = $$v;
      },
      expression: "selectedEntity"
    }
  }, _vm._l(_vm.entity_list, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }