var render = function render(){
  var _vm$user$company2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "dklink-inner"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "dklink-sidebar",
      "title": "Честный знак",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref) {
        var hide = _ref.hide;
        return [_vm._v(" DKLink FO "), _c('img', {
          staticClass: "close-sidebar cursor",
          attrs: {
            "src": "/img/integrations/icon-sidebar-close.svg",
            "alt": ""
          },
          on: {
            "click": hide
          }
        })];
      }
    }, {
      key: "footer",
      fn: function (_ref2) {
        var hide = _ref2.hide;
        return [_c('div', {
          staticClass: "b-action-buttons"
        }, [_c('b-button', {
          staticClass: "cursor",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.updateIntegration(hide);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.integration.id ? 'Сохранить' : 'Подключить') + " ")]), _c('b-button', {
          staticClass: "cursor ml-3",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": hide
          }
        }, [_vm._v(" Отмена ")])], 1)];
      }
    }])
  }, [_c('div', {
    staticClass: "sidebar-block info"
  }, [_c('p', [_vm._v(" Кассовая программа DKLINK Front Office обеспечит удобство работы как розничного магазина, так и целой сети. Удобная программа учета на кассе может интегрироваться с мобильными решениями. ")]), _c('div', {
    staticClass: "info__detail"
  }, [_c('img', {
    attrs: {
      "src": "/img/integrations/icon-info-blue.svg",
      "alt": ""
    }
  }), _c('router-link', {
    attrs: {
      "to": "#"
    }
  }, [_vm._v("Подробная справка")]), _vm._v(" по интеграции ")], 1)]), _c('div', {
    staticClass: "sidebar-block token"
  }, [_c('div', {
    staticClass: "text mb-2"
  }), _c('div', {
    staticStyle: {
      "font-style": "normal",
      "font-weight": "500",
      "font-size": "18px",
      "line-height": "16px",
      "color": "#313131"
    }
  }, [_vm._v(" Синхронизируйте Энтерсайт с DKLink ")]), _c('div', {
    staticStyle: {
      "font-style": "normal",
      "font-weight": "400",
      "font-size": "16px",
      "line-height": "24px",
      "margin-top": "16px",
      "color": "#000000"
    }
  }, [_vm._v(" Чтобы завершить интеграцию вставьте токен и идентификатор компании в настройки интеграции DKLink FO ")]), _c('div', {
    staticClass: "text mb-2"
  }, [_vm._v("Токен")]), _c('b-input-group', {
    staticClass: "mt-3",
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('b-input-group-text', {
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": function ($event) {
              return _vm.copy(_vm.integration.token);
            }
          }
        }, [_c('svg', {
          attrs: {
            "width": "16",
            "height": "18",
            "viewBox": "0 0 16 18",
            "fill": "none",
            "xmlns": "http://www.w3.org/2000/svg"
          }
        }, [_c('path', {
          attrs: {
            "d": "M9.93359 18H3.21875C1.66789 18 0.40625 16.7384 0.40625 15.1875V5.66016C0.40625 4.1093 1.66789 2.84766 3.21875 2.84766H9.93359C11.4845 2.84766 12.7461 4.1093 12.7461 5.66016V15.1875C12.7461 16.7384 11.4845 18 9.93359 18ZM3.21875 4.25391C2.44339 4.25391 1.8125 4.8848 1.8125 5.66016V15.1875C1.8125 15.9629 2.44339 16.5938 3.21875 16.5938H9.93359C10.709 16.5938 11.3398 15.9629 11.3398 15.1875V5.66016C11.3398 4.8848 10.709 4.25391 9.93359 4.25391H3.21875ZM15.5586 13.4297V2.8125C15.5586 1.26164 14.297 0 12.7461 0H4.94141C4.55304 0 4.23828 0.314758 4.23828 0.703125C4.23828 1.09149 4.55304 1.40625 4.94141 1.40625H12.7461C13.5215 1.40625 14.1523 2.03714 14.1523 2.8125V13.4297C14.1523 13.8181 14.4671 14.1328 14.8555 14.1328C15.2438 14.1328 15.5586 13.8181 15.5586 13.4297Z",
            "fill": "#313131"
          }
        })])])];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    staticStyle: {
      "max-width": "630px"
    },
    attrs: {
      "disabled": "",
      "value": _vm.integration.token
    }
  })], 1), _c('div', {
    staticClass: "text mb-2"
  }, [_vm._v("Идентификатор компании")]), _c('b-input-group', {
    staticClass: "mt-3",
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('b-input-group-text', {
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": function ($event) {
              var _vm$user$company;

              return _vm.copy((_vm$user$company = _vm.user.company) === null || _vm$user$company === void 0 ? void 0 : _vm$user$company.id);
            }
          }
        }, [_c('svg', {
          attrs: {
            "width": "16",
            "height": "18",
            "viewBox": "0 0 16 18",
            "fill": "none",
            "xmlns": "http://www.w3.org/2000/svg"
          }
        }, [_c('path', {
          attrs: {
            "d": "M9.93359 18H3.21875C1.66789 18 0.40625 16.7384 0.40625 15.1875V5.66016C0.40625 4.1093 1.66789 2.84766 3.21875 2.84766H9.93359C11.4845 2.84766 12.7461 4.1093 12.7461 5.66016V15.1875C12.7461 16.7384 11.4845 18 9.93359 18ZM3.21875 4.25391C2.44339 4.25391 1.8125 4.8848 1.8125 5.66016V15.1875C1.8125 15.9629 2.44339 16.5938 3.21875 16.5938H9.93359C10.709 16.5938 11.3398 15.9629 11.3398 15.1875V5.66016C11.3398 4.8848 10.709 4.25391 9.93359 4.25391H3.21875ZM15.5586 13.4297V2.8125C15.5586 1.26164 14.297 0 12.7461 0H4.94141C4.55304 0 4.23828 0.314758 4.23828 0.703125C4.23828 1.09149 4.55304 1.40625 4.94141 1.40625H12.7461C13.5215 1.40625 14.1523 2.03714 14.1523 2.8125V13.4297C14.1523 13.8181 14.4671 14.1328 14.8555 14.1328C15.2438 14.1328 15.5586 13.8181 15.5586 13.4297Z",
            "fill": "#313131"
          }
        })])])];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    staticStyle: {
      "max-width": "630px"
    },
    attrs: {
      "disabled": "",
      "value": (_vm$user$company2 = _vm.user.company) === null || _vm$user$company2 === void 0 ? void 0 : _vm$user$company2.id
    }
  })], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }