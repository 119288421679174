var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-sidebar', {
    attrs: {
      "id": "massa-k-sidebar",
      "title": "МАССА-К. ВПН",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref) {
        var hide = _ref.hide;
        return [_c('div', {
          staticClass: "header-title-block"
        }, [_c('div', {
          staticClass: "header-title"
        }, [_vm._v("МАССА-К. ВПН")]), _vm.is_active ? _c('div', {
          staticClass: "header-title-status-block"
        }, [_c('div', {
          staticClass: "delimiter"
        }), _c('div', {
          staticClass: "header-title-status"
        }, [_vm._v("Включено")])]) : _vm._e()]), _c('div', {
          staticClass: "header-left-block"
        }, [_vm.is_active ? _c('b-button', {
          staticClass: "cursor header-left-block__button",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.turnOffIntegration(hide);
            }
          }
        }, [_vm._v(" Выключить интеграцию ")]) : _vm._e(), _c('img', {
          staticClass: "close-sidebar cursor",
          attrs: {
            "src": "/img/integrations/icon-sidebar-close.svg",
            "alt": ""
          },
          on: {
            "click": hide
          }
        })], 1)];
      }
    }, !_vm.is_active ? {
      key: "footer",
      fn: function (_ref2) {
        var hide = _ref2.hide;
        return [_c('div', {
          staticClass: "b-action-buttons"
        }, [_c('b-button', {
          staticClass: "cursor",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.createMassaKIntegration
          }
        }, [_vm._v(" Подключить ")]), _c('b-button', {
          staticClass: "cursor ml-3",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": hide
          }
        }, [_vm._v(" Отмена ")])], 1)];
      }
    } : null], null, true)
  }, [!_vm.is_active ? _c('div', {
    staticClass: "sidebar-block info"
  }, [_c('p', [_vm._v("Российский производитель электронных весов для торговли")]), _c('div', {
    staticClass: "info__detail"
  }, [_c('img', {
    attrs: {
      "src": "/img/integrations/icon-info-blue.svg",
      "alt": ""
    }
  }), _c('router-link', {
    attrs: {
      "to": "#"
    }
  }, [_vm._v("Подробная справка")]), _vm._v(" по интеграции ")], 1)]) : _vm._e(), [_vm.isLoading ? _c('div', {
    staticClass: "loader-block"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary",
      "label": "Загрузка..."
    }
  })], 1) : _vm._e(), _vm.is_active ? _c('div', {
    staticClass: "sidebar-body"
  }, [_c('div', {
    staticClass: "finish-intigration-block"
  }, [_c('img', {
    attrs: {
      "src": "/img/integrations/frontol/frontol-ok.svg",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "finish-intigration-block__text"
  }, [_vm._v("Интеграция успешно подключена")]), _c('img', {
    attrs: {
      "src": "/img/integrations/scales-img.svg",
      "alt": ""
    }
  }), _c('b-button', {
    staticClass: "cursor finish-intigration-block__button",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.toDevices
    }
  }, [_vm._v(" Перейти к спискам товаров на весах ")])], 1)]) : _vm._e()]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }