<template>
  <div
    class="b-api-item"
    @click="on_click"
  >
    <div class="b-api-item__top">
      <div class="logo-shadow">
        <img
          :src="data.img"
          alt=""
        />
      </div>

      <b-button :style="data.is_active ? 'background-color: #00CB91; color: white; font-weight: bold' : ''">
        <b-icon
          v-if="data.is_active"
          icon="check"
          class="mr-2"
          scale="1.2"
        />
        {{ data.is_active ? 'Установлено' : 'Установить' }}
      </b-button>
    </div>
    <div class="b-api-item__content">
      <div class="title">{{ data.title }}</div>
      <p>{{ data.description }}</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'IntegrationCard',
    props: {
      is_active: {
        type: Boolean,
        default: () => false
      },
      data: Object
    },
    methods: {
      on_click() {
        this.$emit('open', this.data.name + '-sidebar')
      }
    }
  }
</script>

<style scoped>
  .logo-shadow {
    width: 62px;
    height: 62px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
</style>
