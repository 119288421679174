var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "acc-inner"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "acc-sidebar",
      "title": "1С Бухгалтерия",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref) {
        var hide = _ref.hide;
        return [_vm._v(" 1С Бухгалтерия "), _c('img', {
          staticClass: "close-sidebar cursor",
          attrs: {
            "src": "/img/integrations/icon-sidebar-close.svg",
            "alt": ""
          },
          on: {
            "click": hide
          }
        })];
      }
    }, {
      key: "footer",
      fn: function (_ref2) {
        var hide = _ref2.hide;
        return [_c('div', {
          staticClass: "b-action-buttons"
        }, [_c('b-button', {
          staticClass: "cursor",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.updateIntegration(hide);
            }
          }
        }, [_vm._v(" " + _vm._s('Сохранить') + " ")]), _c('b-button', {
          staticClass: "cursor ml-3",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": hide
          }
        }, [_vm._v(" Отмена ")])], 1)];
      }
    }])
  }, [_c('div', {
    staticClass: "sidebar-block info"
  }, [_c('p', [_vm._v("Подключите 1С Бухгалтерию для автоматической передачи документов")]), _c('div', {
    staticClass: "info__detail"
  }, [_c('img', {
    attrs: {
      "src": "/img/integrations/icon-info-blue.svg",
      "alt": ""
    }
  }), _c('router-link', {
    attrs: {
      "to": "#"
    }
  }, [_vm._v("Подробная справка")]), _vm._v(" по интеграции ")], 1)]), _c('div', {
    staticClass: "sidebar-block token"
  }, [_c('div', {
    staticClass: "text mb-2"
  }), _c('div', {
    staticClass: "text mb-2"
  }, [_vm._v("ID Key")]), _c('b-input'), _c('div', {
    staticClass: "text mb-2"
  }, [_vm._v("Адрес сервера 1С")]), _c('b-input')], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }