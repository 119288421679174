<template>
  <div
    v-b-toggle.bixolon-sidebar
    class="b-api-item"
    @click="on_click"
  >
    <div class="b-api-item__top">
      <div class="img-block">
        <img
          src="/img/integrations/bixolon.svg"
          alt=""
          style="margin: 5px"
        />
      </div>
      <b-button :style="is_active ? 'background-color: #00CB91; color: white; font-weight: bold' : ''">
        <b-icon
          v-if="is_active"
          icon="check"
          class="mr-2"
          scale="1.2"
        />
        {{ is_active ? 'Установлено' : 'Установить' }}
      </b-button>
    </div>
    <div class="b-api-item__content">
      <div class="title">Bixolon</div>
      <p>Специализированный принтер для печати ценников</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BixolonCard',
    props: {
      is_active: {
        type: Boolean,
        default: () => false
      }
    },
    methods: {
      on_click() {
        this.$emit('on_click')
      }
    }
  }
</script>

<style scoped>
  .img-block {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background-color: rgb(236, 120, 32);
    padding: 13px 12px;
  }
</style>
