var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "integrations"
    }
  }, [_c('div', {
    staticClass: "b-search"
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submitHandlerSearch.apply(null, arguments);
      }
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "Поиск по интеграциям "
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1), _c('div', {
    staticClass: "tag-cont"
  }, _vm._l(_vm.tags, function (tag) {
    return _c('div', {
      key: tag.value,
      class: tag.value === _vm.selectedTag ? 'selected-tag-item' : 'tag-item',
      on: {
        "click": function ($event) {
          _vm.selectedTag = tag.value;
        }
      }
    }, [_vm._v(" " + _vm._s(tag.label) + " ")]);
  }), 0), _vm.isLoading ? [_c('div', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary",
      "label": "Загрузка..."
    }
  }), _vm._m(0)], 1)] : [_c('div', {
    staticClass: "content integrations-block",
    staticStyle: {
      "gap": "16px"
    }
  }, _vm._l(_vm.integrations_items, function (int) {
    return _c('div', {
      key: int.name
    }, [_c('integration-card', {
      attrs: {
        "data": int
      },
      on: {
        "open": _vm.openSidebar
      }
    })], 1);
  }), 0)], _c('evo-sections', {
    attrs: {
      "integration": _vm.evo_section
    }
  }), _c('bixolon-sidebar', {
    attrs: {
      "integration": _vm.getBixolonIntegration,
      "is_active": _vm.checkIntegration.bixolon
    },
    on: {
      "refetch": function ($event) {
        return _vm.$apollo.queries.AllIntegrations.refetch();
      }
    }
  }), _c('d-klink', {
    attrs: {
      "integration": _vm.getDklink,
      "is_active": _vm.checkIntegration.dklink
    }
  }), _c('frontol', {
    attrs: {
      "integration": _vm.getFrontol,
      "is_active": _vm.checkIntegration.frontol
    },
    on: {
      "refetch": function ($event) {
        return _vm.$apollo.queries.AllIntegrations.refetch();
      }
    }
  }), _c('my-shop', {
    attrs: {
      "integration": _vm.getMyShop,
      "is_active": _vm.checkIntegration['my_shop']
    }
  }), _c('account-side'), _c('sbis-sidebar', {
    attrs: {
      "data_sbis": _vm.sbis_data
    },
    on: {
      "refetch": function ($event) {
        return _vm.$apollo.queries.AllIntegrations.refetch();
      }
    }
  }), _c('fasol-sidebar', {
    attrs: {
      "data_sbis": _vm.sbis_data
    },
    on: {
      "refetch": function ($event) {
        return _vm.$apollo.queries.AllIntegrations.refetch();
      }
    }
  }), _c('mercury', {
    attrs: {
      "mercury": _vm.mercury
    },
    on: {
      "update-integration": _vm.addMercury
    }
  }), _c('crp-tech', {
    attrs: {
      "crp_tech": _vm.getCrp
    },
    on: {
      "update-integration": _vm.addCrpTech
    }
  }), _c('shtrix-m', {
    attrs: {
      "is_active": _vm.checkIntegration.shtrih_m,
      "integration": _vm.getIntegration.shtrih_m
    },
    on: {
      "update-integration": _vm.addShtrixM,
      "remove-device": _vm.removeDeviceInt
    }
  }), _c('shtrix-m-pc', {
    attrs: {
      "is_active": _vm.checkIntegration.shtrih_m_pc_200c2,
      "integration": _vm.getIntegration.shtrih_m_pc_200c2
    },
    on: {
      "refetch": function ($event) {
        return _vm.$apollo.queries.AllIntegrations.refetch();
      }
    }
  }), _c('egais', {
    attrs: {
      "egais": _vm.utm
    },
    on: {
      "refetch": _vm.refetchEgais
    }
  }), _c('dibal', {
    attrs: {
      "is_active": _vm.checkIntegration.dibal,
      "integration": _vm.getIntegration.dibal
    },
    on: {
      "update-integration": _vm.addDibal,
      "remove-device": _vm.removeDeviceInt
    }
  }), _c('massa-k-sidebar', {
    attrs: {
      "is_active": _vm.checkIntegration.massa_k,
      "integration": _vm.getIntegration.massa_k
    },
    on: {
      "refetch": function ($event) {
        return _vm.$apollo.queries.AllIntegrations.refetch();
      },
      "remove-device": _vm.removeDeviceInt
    }
  }), _c('evotor')], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('strong', [_vm._v("Загрузка...")])]);

}]

export { render, staticRenderFns }