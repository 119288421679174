<template>
  <b-sidebar
    id="shtrih-m-pc-200c2-sidebar"
    title="Штрих М PC-200C2"
    right
    backdrop
    shadow
  >
    <template #header="{ hide }">
      <div class="mr-auto">Штрих М PC-200C2</div>
      <b-button
        v-if="is_active"
        style="margin-right: 12px"
        variant="gray-sidebar"
        @click="removeIntegration"
      >
        Выключить интеграцию
      </b-button>
      <img
        class="cursor"
        src="/img/integrations/icon-sidebar-close.svg"
        alt=""
        @click="hide"
      />
    </template>

    <div class="sidebar-block info">
      <p>Весы со встроенным компьютером, сенсорным монитором и функцией печати этикеток</p>
      <div class="info__detail">
        <img
          src="/img/integrations/icon-info-blue.svg"
          alt=""
        />
        <router-link to="#">Подробная справка</router-link>
        по интеграции
      </div>
    </div>

    <template v-if="is_active">
      <div class="finish-intigration-block">
        <img
          src="/img/integrations/bixolon-ok.svg"
          alt=""
        />
        <p class="finish-intigration-block__text">Интеграция успешно подключена</p>
        <b-button
          variant="primary"
          class="cursor finish-intigration-block__button"
          @click="toDevices"
        >
          Перейти к списку весов
        </b-button>
      </div>
    </template>

    <template
      v-if="!is_active"
      #footer="{ hide }"
    >
      <div class="b-action-buttons">
        <b-button
          variant="primary"
          class="cursor"
          @click="setIntegration"
        >
          Подключить
        </b-button>
        <b-button
          variant="outline-primary"
          class="cursor ml-3"
          @click="hide"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
  export default {
    name: 'ShtrixMPc',

    props: {
      is_active: {
        type: Boolean,
        default: false
      },
      integration: {
        type: Object,
        default: () => {}
      }
    },

    methods: {
      async setIntegration() {
        try {
          await this.$apollo.mutate({
            mutation: require('../../gql/IniеDevice.graphql'),
            fetchPolicy: 'no-cache',
            variables: {
              input: {
                type: 'shtrih-m-pc-200c2',
                tags: ['scales']
              }
            }
          })
          this.$emit('refetch')
        } catch (e) {
          console.error(e)
        }
      },

      async removeIntegration() {
        await this.$apollo.mutate({
          mutation: require('../../gql/RemoveDeviceIntegration.graphql'),
          variables: {
            ids: [this.integration.id]
          }
        })
        this.$emit('refetch')
      },

      toDevices() {
        this.$router.push({
          name: 'devices.list',
          query: {
            tab: '1'
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    #shtrih-m-pc-200c2-sidebar {
      width: 680px !important;
      display: flex;
      flex-direction: column;

      .info {
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin-left: 22px;
        margin-right: 22px;

        &__detail {
          background: #e6f0ff;
          border-radius: 4px;
          margin-top: 22px;
          border-left: 3px solid #b9d6ff;
          padding: 15px;

          img {
            margin-right: 15px;
          }

          a {
            color: #313131;
            font-weight: 600;
            border-bottom: 1px solid #313131;
          }
        }

        p {
          color: #000;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin-top: 22px;
        }
      }

      .b-sidebar-header {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 85px;

        img.close-sidebar {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 22px;
        }
      }

      .b-sidebar-footer {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 65px;
        display: flex;
        align-items: center;
        padding-left: 22px;
      }

      .b-sidebar-body {
        background: #fff;
        padding: 0;
        display: flex;
        flex-direction: column;

        .sidebar-block {
          .block-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 16px;
            color: #313131;
            margin-bottom: 16px;
          }
        }
      }
    }
  }

  .finish-intigration-block {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    &__text {
      color: var(--text-primary-gray-700, #313131);
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 16px;
    }
    &__button {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding: 22px;
      margin-top: 12px;
    }
  }
</style>
