<template>
  <b-sidebar
    id="frontol-sidebar"
    title="Frontol 6"
    right
    backdrop
    shadow
    @shown="setIntegration('frontol')"
  >
    <template #header="{ hide }">
      <h3 class="header-title">Frontol 6</h3>
      <div class="header-left-block">
        <b-button
          v-if="is_active"
          variant="primary"
          class="cursor header-left-block__button"
          @click="turnOffIntegration"
        >
          Выключить интеграцию
        </b-button>
        <img
          class="close-sidebar cursor"
          src="/img/integrations/icon-sidebar-close.svg"
          alt=""
          @click="hide"
        />
      </div>
    </template>

    <div class="sidebar-block info">
      <p>
        Профессиональное программное обеспечение для автоматизации рабочих мест кассира предприятий розничной торговли и
        общественного питания любых форматов и размеров
      </p>
      <div class="info__detail">
        <img
          src="/img/integrations/icon-info-blue.svg"
          alt=""
        />
        <router-link to="#">Подробная справка</router-link>
        по интеграции
      </div>
    </div>

    <template v-if="false">
      <div class="error-block">
        <img
          src="/img/integrations/frontol/frontol-attention.svg"
          alt=""
        />
        <p class="error-block__text">Ошибка</p>
        <p class="error-block__description">
          Программа интеграции для обмена данными не установлена, <a class="error-block__link">скачайте,</a> установите
          и попробуйте заново.
        </p>
      </div>
    </template>

    <template v-if="is_active">
      <div class="finish-intigration-block">
        <img
          src="/img/integrations/frontol/frontol-ok.svg"
          alt=""
        />
        <p class="finish-intigration-block__text">Интеграция успешно подключена</p>
        <b-button
          variant="primary"
          class="cursor finish-intigration-block__button"
          @click="toDevices"
        >
          К списку касс для настройки обмена
        </b-button>
      </div>
    </template>

    <template #footer="{ hide }">
      <div class="b-action-buttons">
        <b-button
          v-if="!is_active"
          variant="primary"
          class="cursor"
          @click="addToken"
        >
          Подключить
        </b-button>
        <b-button
          v-if="!is_active"
          variant="outline-primary"
          class="cursor ml-3"
          @click="hide"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
  import { mapGetters } from 'vuex'
  import * as R from 'ramda'

  export default {
    name: 'Frontol',
    apollo: {
      Roles: {
        query: require('../gql/getRoles.gql'),
        result() {},
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        }
      },

      CashIntegrations: {
        query: require('../gql/getCashIntegrations.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.integrations = R.pathOr([], ['CashIntegrations'], data)
          this.isLoading = false
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
          this.isLoading = false
        }
      }
    },

    props: {
      is_active: Boolean,
      integration: {
        type: Object,
        default: () => {}
      }
    },

    data: () => ({
      search: '',
      token: '',
      id: '',
      role_list: [],
      branch_list: [],
      role_mapping: [],
      branch_mapping: [],
      evotor_store_list: [],
      evotor_role_list: [],
      integrations: [],
      collapse_visible: false,
      isLoading: true,
      isIntegrating: false,
      check_branch_mapping: false
    }),

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      }),

      checkIntegration() {
        return {
          frontol: this.integrations.includes((el) => el.type === 'frontol')
        }
      },

      connect_is_disabled() {
        if (this.token.length > 0) return false
        return true
      }
    },

    methods: {
      toDevices() {
        this.$router.push({
          name: 'devices.list',
          query: {
            tab: '0'
          }
        })
      },

      turnOffIntegration() {},

      submitHandlerSearch() {},

      setIntegration(type) {
        const data = this.integrations.find((el) => el.type === type)

        if (!data) return
        this.setSidebarData(data)
        this.collapse_visible = true
      },

      setSidebarData(data) {
        this.token = data.login
        this.id = data.id
        this.role_list = data.int_roles
        this.branch_list = data.int_branches
        this.evotor_role_list = data.ext_roles
        this.evotor_store_list = data.ext_branches
        this.role_mapping = data.role_mapping || [{ int_id: '', ext_id: '' }]
        this.check_branch_mapping = !!data.branch_mapping
        this.branch_mapping = data.branch_mapping
          ? [...data.branch_mapping, {}]
          : [{ int_id: this.currentBranch.id, ext_id: '' }]
      },

      updateIntegration() {
        this.$apollo
          .mutate({
            mutation: require('../gql/UpdateCashIntegration.gql'),
            variables: {
              id: this.id,
              branch_mapping: this.branch_mapping,
              role_mapping: this.role_mapping /* TODO this.role_mapping */
            }
          })
          .then(() => {
            this.$noty.show(`Интеграция успешна`)
            // this.collapse_visible = true
          })
          .catch((e) => {
            console.error('error: ', e)
            this.$noty.show(`Что-то пошло не так. Попробуйте еще раз`)
          })
      },

      addToken() {
        this.$apollo
          .mutate({
            mutation: require('../gql/InitCashIntegration.gql'),
            variables: {
              // login: this.token,
              type: 'frontol'
            }
          })
          .then(({ data }) => {
            this.setSidebarData(data.InitCashIntegration)
            this.$noty.show(`Интеграция успешна`)
            this.collapse_visible = true
            this.$emit('refetch')
          })
          .catch((e) => {
            console.error('error: ', e)
            this.$noty.show(`Что-то пошло не так. Попробуйте еще раз`)
          })
      }
    }
  }
</script>

<style scoped lang="scss">
  #frontol-sidebar {
    width: 680px;

    .b-sidebar-header {
      .header-title {
        font-size: 1.5rem;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 20px;
      }
      .header-left-block {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 22px;

        &__button {
          color: var(--text-primary-gray-700, #313131);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          border-radius: 2px;
          background: var(--gray-gray-50, #efefef);
          margin-right: 16px;
        }
      }
    }

    .b-sidebar-footer {
      .b-action-buttons {
        padding: 0 22px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    .b-sidebar-body {
      .sidebar-block {
        p {
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }

        a {
          border-bottom: 1px solid #0066ff;
          font-size: 16px;
        }
      }

      .info {
        font-size: 16px;
        line-height: 24px;
        color: #000000;

        &__detail {
          background: #e6f0ff;
          border-radius: 4px;
          margin-top: 22px;
          border-left: 3px solid #b9d6ff;
          padding: 15px;

          img {
            margin-right: 15px;
          }

          a {
            color: #313131;
            font-weight: 600;
            border-bottom: 1px solid #313131;
          }
        }
      }

      .token {
        .text {
          img {
            margin-left: 5px;
          }
        }
      }

      .b-select {
        &__title {
          .item {
            &:nth-child(2) {
              border-left: 1px solid #e1e1e1;
            }
          }
        }
      }
    }

    .error-block {
      width: 344px;
      margin: 158px auto 0 auto;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__text {
        color: var(--text-primary-gray-700, #313131);
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 16px;
      }
      &__description {
        color: var(--text-primary-gray-700, #313131);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      &__link {
        color: var(--text-system-blue-400, #00a3ff);
        text-decoration-line: underline;
      }
    }

    .finish-intigration-block {
      width: 344px;
      margin: 200px auto 0 auto;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__text {
        color: var(--text-primary-gray-700, #313131);
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 16px;
      }
      &__button {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding: 22px;
        margin-top: 12px;
      }
    }
  }

  ::v-deep() {
    #frontol-sidebar {
      width: 680px;

      .b-sidebar-header {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 85px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .b-sidebar-footer {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 65px;
        display: flex;
      }

      .b-sidebar-body {
        background: #fff;
        padding: 0;

        .sidebar-block {
          border-bottom: 1px solid #e1e1e1;
          padding: 22px;

          .block-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 16px;
            color: #313131;
            margin-bottom: 16px;
          }
        }
      }

      .info {
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }

      .token {
        form {
          margin-top: 7px;

          input {
            height: 42px !important;
            border: 1px solid #bcbcbc;
            border-radius: 2px;
            font-size: 16px;
            color: #313131;
          }
        }
      }

      .b-select {
        border: 1px solid #e1e1e1;
        border-radius: 4px;

        &__title {
          border-bottom: 1px solid #e1e1e1;
          display: flex;

          .item {
            width: 50%;
            padding: 8px 0;
            text-align: center;
          }
        }

        &__item {
          display: flex;
          padding: 16px;
          justify-content: space-between;

          input,
          .ivu-select {
            width: 282px;
          }
        }
      }
    }
  }
</style>
