<template>
  <section id="integrations">
    <!-- <div class="content__header">Интеграции</div> -->
    <div class="b-search">
      <b-form @submit.prevent="submitHandlerSearch">
        <b-form-input
          v-model="search"
          type="text"
          placeholder="Поиск по интеграциям "
        />
        <!--        <b-button type="submit" class="btn-api">API</b-button>-->
      </b-form>
    </div>
    <div class="tag-cont">
      <div
        v-for="tag of tags"
        :key="tag.value"
        :class="tag.value === selectedTag ? 'selected-tag-item' : 'tag-item'"
        @click="selectedTag = tag.value"
      >
        {{ tag.label }}
      </div>
    </div>
    <template v-if="isLoading">
      <div class="text-center">
        <b-spinner
          variant="primary"
          label="Загрузка..."
        />
        <div><strong>Загрузка...</strong></div>
      </div>
    </template>
    <template v-else>
      <div
        class="content integrations-block"
        style="gap: 16px"
      >
        <div
          v-for="int in integrations_items"
          :key="int.name"
        >
          <integration-card
            :data="int"
            @open="openSidebar"
          />
        </div>
      </div>
    </template>
    <evo-sections :integration="evo_section" />
    <bixolon-sidebar
      :integration="getBixolonIntegration"
      :is_active="checkIntegration.bixolon"
      @refetch="$apollo.queries.AllIntegrations.refetch()"
    />
    <d-klink
      :integration="getDklink"
      :is_active="checkIntegration.dklink"
    />
    <frontol
      :integration="getFrontol"
      :is_active="checkIntegration.frontol"
      @refetch="$apollo.queries.AllIntegrations.refetch()"
    />
    <my-shop
      :integration="getMyShop"
      :is_active="checkIntegration['my_shop']"
    />
    <account-side />
    <sbis-sidebar
      :data_sbis="sbis_data"
      @refetch="$apollo.queries.AllIntegrations.refetch()"
    />
    <fasol-sidebar
      :data_sbis="sbis_data"
      @refetch="$apollo.queries.AllIntegrations.refetch()"
    />
    <mercury
      :mercury="mercury"
      @update-integration="addMercury"
    />

    <crp-tech
      :crp_tech="getCrp"
      @update-integration="addCrpTech"
    />

    <shtrix-m
      :is_active="checkIntegration.shtrih_m"
      :integration="getIntegration.shtrih_m"
      @update-integration="addShtrixM"
      @remove-device="removeDeviceInt"
    />

    <shtrix-m-pc
      :is_active="checkIntegration.shtrih_m_pc_200c2"
      :integration="getIntegration.shtrih_m_pc_200c2"
      @refetch="$apollo.queries.AllIntegrations.refetch()"
    />

    <egais
      :egais="utm"
      @refetch="refetchEgais"
    />

    <dibal
      :is_active="checkIntegration.dibal"
      :integration="getIntegration.dibal"
      @update-integration="addDibal"
      @remove-device="removeDeviceInt"
    />

    <massa-k-sidebar
      :is_active="checkIntegration.massa_k"
      :integration="getIntegration.massa_k"
      @refetch="$apollo.queries.AllIntegrations.refetch()"
      @remove-device="removeDeviceInt"
    />

    <!-- TODO перенести сайдбар в отдельный компонент -->

    <evotor />
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'
  import EvoSections from '@/views/integrations/components/EvoSections'
  import CrpTech from '@/views/integrations/components/CrpTech'
  import DKlink from '@/views/integrations/components/DKlink'
  import BixolonCard from '@/views/integrations/components/BixolonCard'
  import Egais from '@/views/integrations/components/Egais'
  import MassaKSidebar from '@/views/integrations/components/MassaKSidebar.vue'
  import Mercury from '@/views/integrations/components/Mercury'
  import Evotor from '@/views/integrations/components/Evotor.vue'
  import ShtrixM from '@/views/integrations/components/shtrix-m/ShtrixM.vue'
  import ShtrixMPc from '@/views/integrations/components/shtrix-m/ShtrixMPc.vue'
  import Dibal from '@/views/integrations/components/dibal/Dibal.vue'
  import AccountSide from '@/views/integrations/components/AccountSide.vue'
  import SbisSidebar from '@/views/integrations/components/SbisSidebar'
  import BixolonSidebar from '@/views/integrations/components/BixolonSidebar.vue'
  import FasolSidebar from '@/views/integrations/components/FasolSidebar.vue'
  import MyShop from '@/views/integrations/components/MyShop.vue'
  import IntegrationCard from '@/views/integrations/components/IntegrationCard.vue'
  import Frontol from '@/views/integrations/components/Frontol.vue'

  export default {
    components: {
      BixolonCard,
      BixolonSidebar,
      Frontol,
      IntegrationCard,
      MyShop,
      FasolSidebar,
      SbisSidebar,
      AccountSide,
      MassaKSidebar,
      Dibal,
      ShtrixM,
      ShtrixMPc,
      Evotor,
      Mercury,
      DKlink,
      Egais,
      EvoSections,
      CrpTech
    },
    apollo: {
      Roles: {
        query: require('../gql/getRoles.gql'),
        result() {},
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        }
      },
      getUtm: {
        query: require('../gql/getEgaisUtm.graphql'),
        variables() {
          return {
            input: {
              branch: this.currentBranch.id
            }
          }
        },
        result({ data }) {
          this.utm = { ...data.getUtm, entity: data.getUtm?.entity?.id, branch: data.getUtm?.branch?.id }
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        }
      },

      // CashIntegrations: {
      //   query: require('../gql/getCashIntegrations.gql'),
      //   fetchPolicy: 'no-cache',
      //   result({ data }) {
      //     this.integrations = data?.CashIntegrations ?? []
      //
      //     this.isLoading = false
      //   },
      //   errorPolicy: 'ignore',
      //   error(error) {
      //     console.error(`Ошибка запроса: ${error}`)
      //     this.isLoading = false
      //   }
      // },

      AllIntegrations: {
        query: require('../gql/getIntegrations.graphql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          let non_cash = []
          let cash = []
          let device = []
          const sbis = data?.AllIntegrations?.user_integrations?.find((obj) => obj?.type === 'sbis')
          if (sbis) {
            this.sbis_data = {
              login: sbis?.login,
              password: sbis?.password,
              entity: sbis?.entity,
              thumbprint: sbis?.signature?.thumbprint
            }
          }
          let user_int = data?.AllIntegrations?.user_integrations ?? []
          non_cash = data?.AllIntegrations?.integrations ?? []
          cash = data?.AllIntegrations?.cash_integrations ?? []
          device = data?.AllIntegrations?.device_integrations ?? []
          this.integrations = [...cash, ...non_cash, ...device, ...user_int]
          this.isLoading = false
        }
      }
    },

    data: () => ({
      utm: null,
      tags: [
        {
          label: 'Все интеграции',
          value: null
        },
        { label: 'Мои интеграции', value: 'installed' },
        { label: 'Принтеры', value: 'printer' },
        { label: 'Кассовые системы', value: 'cash' },
        { label: 'Весы', value: 'weight' },
        { label: 'Государственные системы', value: 'national' },
        { label: 'Бухгалтерия', value: 'accounting' },
        { label: 'Эвотор', value: 'evotor' },
        { label: 'ЭДО', value: 'edo' },
        { label: 'Франшизы', value: 'franchise' },
        { label: 'Лояльность', value: 'loyalty' }
      ],
      integrations_list: [
        {
          name: 'massa-k',
          img: '/img/integrations/massa-k.svg',
          title: 'МАССА-К. ВПН',
          description: 'Российский производитель электронных весов для торговли',
          tag: ['weight']
        },
        {
          name: 'evotor',
          img: '/img/integrations/evotor.png',
          title: 'Эвотор',
          description:
            'Настройте связку и передавайте наименования, цены и другие сведения о товарах, блюдах и услугах.',
          tag: ['cash']
        },
        {
          name: 'crptech',
          img: '/img/integrations/crptech.svg',
          title: 'Честный знак',
          description: 'Национальная система цифровой маркировки и прослеживания товаров',
          tag: ['national', 'edo']
        },
        {
          name: 'evosections',
          img: '/img/integrations/sections.png',
          title: 'Секции и отделы',
          description: 'Разделение чека по секциям, отделам и системам налогообложения (СНО)',
          tag: ['evotor']
        },
        {
          name: 'dklink',
          img: '/img/integrations/dklinkicon.png',
          title: 'DKlink',
          description:
            'Настройте связку и передавайте наименования, цены и другие сведения о товарах, блюдах и услугах.',
          tag: ['cash']
        },
        {
          name: 'mercury',
          img: '/img/integrations/mercury.svg',
          title: 'Меркурий',
          description: 'Система электронной сертификации поднадзорных госветнадзору грузов',
          tag: ['national']
        },
        {
          name: 'shtrih_m',
          img: '/img/integrations/shtrixm.png',
          title: 'Штрих М 15-2.5',
          description:
            'Весы с печатью этикетки, которые могут использоваться для фасовки товара и для работы в торговом зале',
          tag: ['weight']
        },
        {
          name: 'shtrih-m-pc-200c2',
          img: '/img/integrations/shtrixm.png',
          title: 'Штрих М PC-200C2',
          description: 'Весы со встроенным компьютером, сенсорным монитором и функцией печати этикеток',
          tag: ['weight']
        },
        {
          name: 'dibal',
          img: '/img/integrations/dibal.png',
          title: 'Dibal 525',
          description: 'Торговые весы с печатью этикеток используют для фасовки весового товара',
          tag: ['weight']
        },
        {
          name: 'sbis',
          img: '/img/integrations/sbis_logo.svg',
          title: 'СБИС ЭДО',
          description: 'Электронная подпись и обмен документами',
          tag: ['edo']
        },
        {
          name: 'egais',
          img: '/img/integrations/egais.svg',
          title: 'ЕГАИС',
          description: 'Федеральная служба по регулированию алкогольного рынка',
          tag: ['national']
        },
        {
          name: 'fasol',
          img: '/img/integrations/fasol_logo.svg',
          title: 'Фасоль',
          description: 'Магазины франчайзинговой программы компании METRO',
          tag: ['franchise']
        },
        {
          name: 'my_shop',
          img: '/img/integrations/myshop.png',
          title: 'Мой магазин',
          description:
            'Удобные и понятные инструменты маркетинга для повышения дохода и снижения маркетинговых издержек',
          tag: ['loyalty']
        },
        {
          name: 'frontol',
          img: '/img/integrations/frontol.svg',
          title: 'Frontol 6',
          description: 'Программное обеспечения для автоматизации рабочих мест кассира предприятий розничной торговли',
          tag: ['cash']
        },
        {
          name: 'bixolon',
          img: '/img/integrations/bixolon-logo.svg',
          title: 'Bixolon',
          description: 'Специализированный принтер для печати ценников',
          tag: ['printer']
        }
      ],
      selectedTag: null,
      isIntegrating: false,
      search: '',
      token: '',
      id: '',
      sbis_data: {
        login: '',
        password: '',
        entity: [],
        thumbprint: ''
      },
      role_list: [],
      branch_list: [],
      role_mapping: [],
      branch_mapping: [],
      evotor_store_list: [],
      evotor_role_list: [],
      integrations: [],
      deviceIntegrations: [],
      collapse_visible: false,
      isLoading: true,
      check_branch_mapping: false,
      evo_section: {}
    }),

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        branches_list: 'settings/getBranchList'
      }),
      getCrp() {
        return this.integrations?.find((el) => el.type === 'crptech') ?? {}
      },
      getBixolonIntegration() {
        return this.integrations?.find((el) => el.type === 'bixolon') ?? {}
      },
      getDklink() {
        return this.integrations?.find((el) => el.type === 'dklink') ?? {}
      },
      getFrontol() {
        return this.integrations?.find((el) => el.type === 'frontol') ?? {}
      },
      getMyShop() {
        return this.integrations?.find((el) => el.type === 'my_shop') ?? {}
      },

      mercury() {
        return this.integrations?.find((el) => el.type === 'mercury') ?? {}
      },
      integrations_items() {
        return this.integrations_list
          .map((int) => {
            let is_active = this.integrations.some((el) => el.type === int.name)
            if (int.name === 'egais') is_active = this.utm?.branch === this.currentBranch.id
            if (int.name === 'dibal') is_active = this.integrations.some((el) => el.type === 'dibal_500')
            if (int.name === this.currentBranch.franchise) is_active = true
            return {
              ...int,
              is_active
            }
          })
          .filter((el) => {
            let flag = el.title.toLowerCase().includes(this.search?.toLowerCase())
            if (this.selectedTag) {
              if (this.selectedTag === 'installed') flag = flag && el.is_active
              else flag = flag && el.tag.includes(this.selectedTag)
            }

            return flag
          })
          .sort((a, b) => (a.is_active > b.is_active ? -1 : 1))
      },
      checkIntegration() {
        return {
          evotor: this.integrations.some((el) => el.type === 'evotor'),
          crptech: this.integrations.some((el) => el.type === 'crptech'),
          evosections: this.integrations.some((el) => el.type === 'evosections'),
          dklink: this.integrations.some((el) => el.type === 'dklink'),
          mercury: this.integrations.some((el) => el.type === 'mercury'),
          shtrih_m: this.integrations.some((el) => el.type === 'shtrih_m'),
          shtrih_m_pc_200c2: this.integrations.some((el) => el.type === 'shtrih-m-pc-200c2'),
          dibal: this.integrations.some((el) => el.type === 'dibal_500'),
          sbis: this.integrations.some((el) => el.type === 'sbis'),
          egais: this.utm?.branch === this.currentBranch.id,
          fasol: this.branches_list.some((el) => el.franchise === 'fasol'),
          my_shop: this.integrations.some((el) => el.type === 'my_shop'),
          bixolon: this.integrations.some((el) => el.type === 'bixolon'),
          frontol: this.integrations.some((el) => el.type === 'frontol'),
          massa_k: this.integrations.some((el) => el.type === 'massa-k')
        }
      },
      getIntegration() {
        return {
          evotor: this.integrations.find((el) => el.type === 'evotor'),
          crptech: this.integrations.find((el) => el.type === 'crptech'),
          evosections: this.integrations.find((el) => el.type === 'evosections'),
          dklink: this.integrations.find((el) => el.type === 'dklink'),
          mercury: this.integrations.find((el) => el.type === 'mercury'),
          shtrih_m: this.integrations.find((el) => el.type === 'shtrih_m'),
          shtrih_m_pc_200c2: this.integrations.find((el) => el.type === 'shtrih-m-pc-200c2'),
          dibal: this.integrations.find((el) => el.type === 'dibal_500'),
          sbis: this.integrations.find((el) => el.type === 'sbis'),
          egais: this.utm?.branch === this.currentBranch.id,
          bixolon: this.integrations.find((el) => el.type === 'bixolon'),
          frontol: this.integrations.find((el) => el.type === 'frontol'),
          massa_k: this.integrations.find((el) => el.type === 'massa-k')
        }
      },

      connect_is_disabled() {
        if (this.token?.length > 0) return false
        return true
      }
    },

    methods: {
      openSidebar(id) {
        this.$root.$emit('bv::toggle::collapse', id)
      },
      submitHandlerSearch() {},
      setIntegrationShtrixM() {},
      setIntegrationDibal() {},
      setSections() {
        this.evo_section = this.integrations.find((el) => el.type === 'evosections')
      },

      selectTag() {
        // this.
      },

      setIntegration(type) {
        const data = this.integrations.find((el) => el.type === type)

        // if (!data) return
        if (data && data.type === 'evotor') {
          this.setSidebarData(data)
          this.collapse_visible = true
        }
      },
      setIntegrationDk(type) {
        const data = this.integrations.find((el) => el.type === type)

        // if (!data) return
        if (data && data.type === 'evotor') {
          this.setSidebarData(data)
          this.collapse_visible = true
        }
      },

      setSidebarData(data) {
        this.token = data.login
        this.id = data.id
        this.role_list = data.int_roles
        this.branch_list = data.int_branches
        this.evotor_role_list = data.ext_roles
        this.evotor_store_list = data.ext_branches
        this.role_mapping = data.role_mapping || [{ int_id: '', ext_id: '' }]
        this.check_branch_mapping = !!data.branch_mapping
        this.branch_mapping = data.branch_mapping
          ? [...data.branch_mapping, {}]
          : [{ int_id: this.currentBranch.id, ext_id: '' }]
      },

      updateIntegration() {
        this.$apollo
          .mutate({
            mutation: require('../gql/UpdateCashIntegration.gql'),
            variables: {
              id: this.id,
              branch_mapping: this.branch_mapping.filter((el) => el.ext_id && el.int_id),
              role_mapping: this.role_mapping
            }
          })
          .then(({ data }) => {
            this.check_branch_mapping = !!data?.UpdateCashIntegration?.branch_mapping
            this.$noty.show(`Интеграция успешна`)
            // this.collapse_visible = true
          })
          .catch((e) => {
            console.error('error: ', e)
            this.$noty.show(`Что-то пошло не так. Попробуйте еще раз`)
          })
      },

      async addMercury(int, cb, err) {
        try {
          await this.$apollo.mutate({
            mutation: require('../gql/InitCrpTech.graphql'),
            fetchPolicy: 'no-cache',
            variables: {
              input: { ...int, type: 'mercury' }
            }
          })
          await this.$apollo.queries.AllIntegrations.refetch()
          cb()
        } catch (e) {
          console.error(e)
          err()
        }
      },

      async addShtrixM(int, cb, err) {
        try {
          await this.$apollo.mutate({
            mutation: require('../gql/IniеDevice.graphql'),
            fetchPolicy: 'no-cache',
            variables: {
              input: { ...int, type: 'shtrih_m' }
            }
          })
          await this.$apollo.queries.AllIntegrations.refetch()
          cb()
        } catch (e) {
          console.error(e)
          err()
        }
      },
      async addDibal(int, cb, err) {
        try {
          await this.$apollo.mutate({
            mutation: require('../gql/IniеDevice.graphql'),
            fetchPolicy: 'no-cache',
            variables: {
              input: { ...int, type: 'dibal_500' }
            }
          })
          await this.$apollo.queries.AllIntegrations.refetch()
          cb()
        } catch (e) {
          console.error(e)
          err()
        }
      },

      addSbis() {},

      addToken() {
        this.$apollo
          .mutate({
            mutation: require('../gql/InitCashIntegration.gql'),
            variables: {
              login: this.token,
              type: 'evotor'
            }
          })
          .then(({ data }) => {
            this.setSidebarData(data.InitCashIntegration)
            this.$noty.show(`Интеграция успешна`)
            this.collapse_visible = true
          })
          .catch((e) => {
            console.error('error: ', e)
            this.$noty.show(`Что-то пошло не так. Попробуйте еще раз`)
          })
      },
      addCrpTech(crpTech) {
        this.$apollo
          .mutate({
            mutation: require('../gql/InitCrpTech.graphql'),
            variables: {
              input: {
                items: [
                  {
                    branch: crpTech.branch,
                    entity: crpTech.entity,
                    login: crpTech.thumbprint
                  }
                ],
                type: 'crptech'
              }
            }
          })
          .then(() => {
            this.$apollo.queries.AllIntegrations.refetch()
            this.$noty.show(`Интеграция успешна`)
          })
          .catch((e) => {
            console.error('error: ', e)
            this.$noty.error(e.graphQLErrors?.[0]?.extensions?.ru ?? `Что-то пошло не так. Попробуйте еще раз`)
          })
      },
      async uploadClassif() {
        try {
          this.isIntegrating = true
          await this.$apollo.mutate({
            mutation: require('../gql/DownloadClassif.gql'),
            variables: {
              input: {
                branch: this.currentBranch.id,
                integration: this.id
              }
            }
          })
          this.$noty.show(`Импорт данных успешен`)
        } catch (e) {
          console.error(e)
          this.$noty.show(`При импорте что-то пошло не так. Попробуйте еще раз`)
        } finally {
          this.isIntegrating = false
        }
      },
      async exportClassif() {
        this.$bvModal
          .msgBoxConfirm('При экспорте в все данные в эвоторе будут удалены и записаны данные из энтерсайта', {
            title: 'Вы уверенны?',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'Продолжить',
            cancelTitle: 'Отмена',
            cancelVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0 d-flex',
            centered: true
          })
          .then((data) => {
            if (data) this.exportData()
          })
          .catch(() => {
            // An error occurred
          })
      },
      async exportData() {
        try {
          this.isIntegrating = true
          await this.$apollo.mutate({
            mutation: require('../gql/UploadClassif.gql'),
            variables: {
              integration: this.id
            }
          })
          this.$noty.show(`Экспорт данных успешен`)
        } catch (e) {
          console.error(e)
          this.$noty.show(`При экспорте что-то пошло не так. Попробуйте еще раз`)
        } finally {
          this.isIntegrating = false
        }
      },
      addExtBranch() {
        if (
          this.branch_mapping[this.branch_mapping.length - 1]?.ext_id ||
          this.branch_mapping[this.branch_mapping.length - 1]?.int_id
        )
          this.branch_mapping.push({})
      },
      async removeDeviceInt(data) {
        await this.$apollo.mutate({
          mutation: require('../gql/RemoveDeviceIntegration.graphql'),
          variables: {
            ids: [data.id]
          }
        })
        this.$apollo.queries.AllIntegrations.refetch()
      },
      refetchEgais() {
        this.$apollo.queries.getUtm.refetch()
        this.utm.branch = this.currentBranch.id
      }
    }
  }
</script>

<style lang="scss" scoped>
  .integrations-block {
    display: grid;
    grid-template-columns: repeat(auto-fit, 292px);
  }

  .modal-footer button {
    margin: 0 !important;
  }

  #integrations {
    // padding-top: 40px;

    .tag-cont {
      display: flex;
      flex-direction: row;
      gap: 16px;
      margin-bottom: 22px;
    }

    .tag-item {
      background: #ffffff;
      color: #bcbcbc;
      font-weight: 500;
      border-radius: 2px;
      padding: 10px;
      cursor: pointer;
    }

    .selected-tag-item {
      background: #ddf3ff;
      color: #00a3ff;
      font-weight: 500;
      border-radius: 2px;
      padding: 10px;
      cursor: pointer;
    }

    ::v-deep .content__header {
      font-size: 28px;
      font-weight: 500;
    }

    .b-search {
      margin-top: 0;
      margin-bottom: 22px;

      ::v-deep form {
        display: flex;

        input {
          background: url('/img/integrations/icon_search.svg') 13px 50% no-repeat;
          height: 42px !important;
          padding-left: 46px;
          font-size: 16px;
          box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
          border-radius: 2px;
          max-width: 700px;
        }

        button.btn-api {
          background: #d9e8ff;
          border-radius: 2px;
          font-size: 18px;
          text-align: center;
          color: #0066ff;
          height: 42px;
          padding: 0 40px;
          margin-left: 8px;
        }
      }
    }

    ::v-deep .b-api-item {
      width: 292px;
      height: 183px;
      background: #ffffff;
      border-radius: 4px;
      padding: 16px;
      cursor: pointer;

      &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
          background: #efefef;
          color: #313131;
        }
      }

      &__content {
        margin-top: 12px;

        .title {
          font-size: 18px;
          color: #313131;
        }

        p {
          padding: 0;
          margin: 0;
          color: #888888;
          line-height: 16px;
        }
      }
    }

    ::v-deep #sidebar-right {
      width: 680px;

      .b-sidebar-header {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

        img.close-sidebar {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 22px;
        }
      }

      .b-sidebar-footer {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 65px;
        display: flex;

        .b-action-buttons {
          padding: 0 22px 0;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .b-sidebar-body {
        background: #fff;
        padding: 0;

        .sidebar-block {
          border-bottom: 1px solid #e1e1e1;
          padding: 22px;

          .block-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 16px;
            color: #313131;
            margin-bottom: 16px;
          }

          p {
            font-size: 16px;
            line-height: 24px;
            color: #000000;
          }

          a {
            border-bottom: 1px solid #0066ff;
            font-size: 16px;
          }
        }

        .info {
          font-size: 16px;
          line-height: 24px;
          color: #000000;

          &__detail {
            background: #e6f0ff;
            border-radius: 4px;
            margin-top: 22px;
            border-left: 3px solid #b9d6ff;
            padding: 15px;

            img {
              margin-right: 15px;
            }

            a {
              color: #313131;
              font-weight: 600;
              border-bottom: 1px solid #313131;
            }
          }
        }

        .token {
          .text {
            margin-top: 16px;

            img {
              margin-left: 5px;
            }
          }

          form {
            margin-top: 7px;

            input {
              height: 42px !important;
              border: 1px solid #bcbcbc;
              border-radius: 2px;
              font-size: 16px;
              color: #313131;
            }
          }
        }

        .b-select {
          border: 1px solid #e1e1e1;
          border-radius: 4px;

          &__title {
            border-bottom: 1px solid #e1e1e1;
            display: flex;

            .item {
              width: 50%;
              padding: 8px 0;
              text-align: center;

              &:nth-child(2) {
                border-left: 1px solid #e1e1e1;
              }
            }
          }

          &__item {
            display: flex;
            padding: 16px;
            justify-content: space-between;

            input,
            .ivu-select {
              width: 282px;
            }
          }
        }
      }
    }
  }
</style>
