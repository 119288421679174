<template>
  <section id="acc-inner">
    <b-sidebar
      id="acc-sidebar"
      title="1С Бухгалтерия"
      right
      backdrop
      shadow
    >
      <template #header="{ hide }">
        1С Бухгалтерия
        <img
          class="close-sidebar cursor"
          src="/img/integrations/icon-sidebar-close.svg"
          alt=""
          @click="hide"
        />
      </template>

      <div class="sidebar-block info">
        <p>Подключите 1С Бухгалтерию для автоматической передачи документов</p>
        <div class="info__detail">
          <img
            src="/img/integrations/icon-info-blue.svg"
            alt=""
          />
          <router-link to="#">Подробная справка</router-link>
          по интеграции
        </div>
      </div>
      <div class="sidebar-block token">
        <div class="text mb-2">
          <!--          <img src="/img/integrations/icon-info-gray.svg" alt="" />-->
          <!--          <b-form @submit.prevent="addToken">-->
          <!--            <b-form-input-->
          <!--              v-model="token"-->
          <!--              type="text"-->
          <!--              placeholder="Номер токена"-->
          <!--            />-->
          <!--          </b-form>-->
        </div>
        <div class="text mb-2">ID Key</div>
        <b-input />

        <div class="text mb-2">Адрес сервера 1С</div>
        <b-input />
      </div>
      <template #footer="{ hide }">
        <div class="b-action-buttons">
          <b-button
            variant="primary"
            class="cursor"
            @click="updateIntegration(hide)"
          >
            {{ 'Сохранить' }}
          </b-button>
          <b-button
            variant="outline-primary"
            class="cursor ml-3"
            @click="hide"
          >
            Отмена
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'AccountSide',
    props: {
      is_active: Boolean,
      integration: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        certs: [],
        selectedCert: '',
        selectedBranch: '',
        selectedEntity: '',
        token: ''
      }
    },
    async mounted() {
      const localstorage = JSON.parse(localStorage.getItem('entersight'))
      const token = localstorage ? localstorage.core.token : ''
      this.token = token
    },
    methods: {
      async copy(info) {
        await navigator.clipboard.writeText(info)
        this.$noty.show(`Скопированно`)
      },
      updateIntegration(hide) {
        // this.$noty.show(`Интеграция успешна`)
        hide()
      },
      formatDate(date) {
        return new Intl.DateTimeFormat('ru-RU').format(new Date(date))
      },
      clearEntity() {
        this.selectedEntity = ''
      },
      clearBranch() {
        this.selectedBranch = ''
      }
    },
    computed: {
      ...mapGetters({
        branches: 'settings/getBranchList',
        user: 'core/user'
      }),
      entities() {
        return this.branches.find((el) => el.id === this.selectedBranch)?.entities ?? []
      },
      inn_error() {
        const cert = this.certs?.find((el) => el.thumbprint === this.selectedCert)
        const entity = this.entities?.find((el) => el.id === this.selectedEntity)

        if (!cert || !entity) return true

        return !cert.subjectName.includes(entity.inn)
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    #acc-sidebar {
      width: 680px !important;

      .b-sidebar-header {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 85px;
      }

      .b-sidebar-footer {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 65px;
        display: flex;
      }

      .b-sidebar-body {
        background: #fff;
        padding: 0;

        .sidebar-block {
          .block-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 16px;
            color: #313131;
            margin-bottom: 16px;
          }

          a {
            border-bottom: 1px solid #0066ff;
            font-size: 16px;
          }
        }

        .info {
          &__detail {
            a {
              color: #313131;
              font-weight: 600;
              border-bottom: 1px solid #313131;
            }
          }
        }

        .token {
          form {
            margin-top: 7px;

            input {
              height: 42px !important;
              border: 1px solid #bcbcbc;
              border-radius: 2px;
              font-size: 16px;
              color: #313131;
            }
          }
        }

        .b-select {
          border: 1px solid #e1e1e1;
          border-radius: 4px;

          &__title {
            border-bottom: 1px solid #e1e1e1;
            display: flex;

            .item {
              width: 50%;
              padding: 8px 0;
              text-align: center;
            }
          }

          &__item {
            display: flex;
            padding: 16px;
            justify-content: space-between;

            input,
            .ivu-select {
              width: 282px;
            }
          }
        }
      }
    }
  }

  #acc-inner {
    #acc-sidebar {
      width: 680px !important;

      .b-sidebar-header {
        img.close-sidebar {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 22px;
        }
      }

      .b-sidebar-footer {
        .b-action-buttons {
          padding: 0 22px 0;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .b-sidebar-body {
        .sidebar-block {
          border-bottom: 1px solid #e1e1e1;
          padding: 22px;

          p {
            font-size: 16px;
            line-height: 24px;
            color: #000000;
          }
        }

        .info {
          font-size: 16px;
          line-height: 24px;
          color: #000000;

          &__detail {
            background: #e6f0ff;
            border-radius: 4px;
            margin-top: 22px;
            border-left: 3px solid #b9d6ff;
            padding: 15px;

            img {
              margin-right: 15px;
            }
          }
        }

        .token {
          .text {
            margin-top: 16px;

            img {
              margin-left: 5px;
            }
          }
        }

        .b-select {
          &__title {
            .item {
              &:nth-child(2) {
                border-left: 1px solid #e1e1e1;
              }
            }
          }
        }
      }
    }
  }
</style>
