var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-sidebar', {
    attrs: {
      "id": "shtrih-m-pc-200c2-sidebar",
      "title": "Штрих М PC-200C2",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref) {
        var hide = _ref.hide;
        return [_c('div', {
          staticClass: "mr-auto"
        }, [_vm._v("Штрих М PC-200C2")]), _vm.is_active ? _c('b-button', {
          staticStyle: {
            "margin-right": "12px"
          },
          attrs: {
            "variant": "gray-sidebar"
          },
          on: {
            "click": _vm.removeIntegration
          }
        }, [_vm._v(" Выключить интеграцию ")]) : _vm._e(), _c('img', {
          staticClass: "cursor",
          attrs: {
            "src": "/img/integrations/icon-sidebar-close.svg",
            "alt": ""
          },
          on: {
            "click": hide
          }
        })];
      }
    }, !_vm.is_active ? {
      key: "footer",
      fn: function (_ref2) {
        var hide = _ref2.hide;
        return [_c('div', {
          staticClass: "b-action-buttons"
        }, [_c('b-button', {
          staticClass: "cursor",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.setIntegration
          }
        }, [_vm._v(" Подключить ")]), _c('b-button', {
          staticClass: "cursor ml-3",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": hide
          }
        }, [_vm._v(" Отмена ")])], 1)];
      }
    } : null], null, true)
  }, [_c('div', {
    staticClass: "sidebar-block info"
  }, [_c('p', [_vm._v("Весы со встроенным компьютером, сенсорным монитором и функцией печати этикеток")]), _c('div', {
    staticClass: "info__detail"
  }, [_c('img', {
    attrs: {
      "src": "/img/integrations/icon-info-blue.svg",
      "alt": ""
    }
  }), _c('router-link', {
    attrs: {
      "to": "#"
    }
  }, [_vm._v("Подробная справка")]), _vm._v(" по интеграции ")], 1)]), _vm.is_active ? [_c('div', {
    staticClass: "finish-intigration-block"
  }, [_c('img', {
    attrs: {
      "src": "/img/integrations/bixolon-ok.svg",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "finish-intigration-block__text"
  }, [_vm._v("Интеграция успешно подключена")]), _c('b-button', {
    staticClass: "cursor finish-intigration-block__button",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.toDevices
    }
  }, [_vm._v(" Перейти к списку весов ")])], 1)] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }