<template>
  <b-sidebar
    id="massa-k-sidebar"
    title="МАССА-К. ВПН"
    right
    backdrop
    shadow
  >
    <template #header="{ hide }">
      <div class="header-title-block">
        <div class="header-title">МАССА-К. ВПН</div>
        <div
          v-if="is_active"
          class="header-title-status-block"
        >
          <div class="delimiter"></div>
          <div class="header-title-status">Включено</div>
        </div>
      </div>
      <div class="header-left-block">
        <b-button
          v-if="is_active"
          variant="primary"
          class="cursor header-left-block__button"
          @click="turnOffIntegration(hide)"
        >
          Выключить интеграцию
        </b-button>
        <img
          class="close-sidebar cursor"
          src="/img/integrations/icon-sidebar-close.svg"
          alt=""
          @click="hide"
        />
      </div>
    </template>

    <div
      v-if="!is_active"
      class="sidebar-block info"
    >
      <p>Российский производитель электронных весов для торговли</p>
      <div class="info__detail">
        <img
          src="/img/integrations/icon-info-blue.svg"
          alt=""
        />
        <router-link to="#">Подробная справка</router-link>
        по интеграции
      </div>
    </div>

    <template>
      <div
        v-if="isLoading"
        class="loader-block"
      >
        <b-spinner
          variant="primary"
          label="Загрузка..."
        />
      </div>

      <div
        v-if="is_active"
        class="sidebar-body"
      >
        <div class="finish-intigration-block">
          <img
            src="/img/integrations/frontol/frontol-ok.svg"
            alt=""
          />
          <p class="finish-intigration-block__text">Интеграция успешно подключена</p>
          <img
            src="/img/integrations/scales-img.svg"
            alt=""
          />
          <b-button
            variant="primary"
            class="cursor finish-intigration-block__button"
            @click="toDevices"
          >
            Перейти к спискам товаров на весах
          </b-button>
        </div>
      </div>
    </template>

    <template
      v-if="!is_active"
      #footer="{ hide }"
    >
      <div class="b-action-buttons">
        <b-button
          variant="primary"
          class="cursor"
          @click="createMassaKIntegration"
        >
          Подключить
        </b-button>
        <b-button
          variant="outline-primary"
          class="cursor ml-3"
          @click="hide"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'MassaKSidebar',

    props: {
      is_active: Boolean,
      integration: {
        type: Object,
        default: () => {}
      }
    },

    data() {
      return {
        isLoading: false
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    },

    methods: {
      async createMassaKIntegration() {
        try {
          this.isLoading = true
          await this.$apollo.mutate({
            mutation: require('../gql/IniеDevice.graphql'),
            fetchPolicy: 'no-cache',
            variables: {
              input: {
                devices: [
                  {
                    branch: this.currentBranch.id,
                    type: 'massa-k',
                    tags: ['scales'],
                    name: 'МАССА-К'
                  }
                ],
                branch: this.currentBranch.id,
                type: 'massa-k'
              }
            }
          })
          this.isLoading = false
          this.$emit('refetch')
        } catch (e) {
          console.error(e)
          this.isLoading = false
        }
      },

      turnOffIntegration(hide) {
        this.$emit('remove-device', this.integration)
        hide()
      },

      toDevices() {
        this.$router.push({
          name: 'devices.list',
          query: {
            tab: '1'
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .sidebar-body {
  }

  .finish-intigration-block {
    width: 344px;
    margin: 74px auto 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__text {
      color: var(--text-primary-gray-700, #313131);
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 32px;
      margin-bottom: 32px;
    }
    &__button {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding: 22px;
      margin-top: 32px;
    }
  }

  .loader-block {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70%;
  }

  .delimiter {
    height: 17px;
    width: 1px;
    border-radius: 14px;
    background: var(--text-placeholder-gray-300, #bcbcbc);
    margin-left: 11px;
    margin-right: 11px;
  }
  .b-sidebar-header {
    .header-title-block {
      display: flex;
      align-items: center;
      margin-top: 20px;
    }
    .header-title {
      font-size: 1.5rem;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: flex;
      align-items: center;
    }

    .header-title-status-block {
      display: flex;
      align-items: center;
    }

    .header-title-status {
      color: var(--text-successful-green-400, #00cb91);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
    .header-left-block {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 22px;

      &__button {
        color: var(--text-primary-gray-700, #313131);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        border-radius: 2px;
        background: var(--gray-gray-50, #efefef);
        margin-right: 16px;
      }
    }
  }

  .b-sidebar-footer {
    .b-action-buttons {
      padding: 0 22px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .b-sidebar-body {
    .sidebar-block {
      p {
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }

      a {
        border-bottom: 1px solid #0066ff;
        //   font-size: 16px;
        color: var(--text-primary-gray-700, #313131);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .info {
      font-size: 16px;
      line-height: 24px;
      color: #000000;

      &__detail {
        background-color: var(--blue-50, #eef9ff);
        border-radius: 4px;
        margin-top: 22px;
        border-left: 3px solid var(--blue-200, #99daff);
        padding: 15px;

        img {
          margin-right: 15px;
        }

        a {
          color: #313131;
          font-weight: 600;
          border-bottom: 1px solid #313131;
        }
      }
    }
  }

  ::v-deep() {
    #massa-k-sidebar {
      width: 680px;

      .b-sidebar-header {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 85px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .b-sidebar-footer {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 65px;
        display: flex;
      }

      .b-sidebar-body {
        background: #fff;
        padding: 0;

        .sidebar-block {
          border-bottom: 1px solid #e1e1e1;
          padding: 22px;

          .block-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 16px;
            color: #313131;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
</style>
