var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-sidebar', {
    attrs: {
      "id": "frontol-sidebar",
      "title": "Frontol 6",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    on: {
      "shown": function ($event) {
        return _vm.setIntegration('frontol');
      }
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref) {
        var hide = _ref.hide;
        return [_c('h3', {
          staticClass: "header-title"
        }, [_vm._v("Frontol 6")]), _c('div', {
          staticClass: "header-left-block"
        }, [_vm.is_active ? _c('b-button', {
          staticClass: "cursor header-left-block__button",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.turnOffIntegration
          }
        }, [_vm._v(" Выключить интеграцию ")]) : _vm._e(), _c('img', {
          staticClass: "close-sidebar cursor",
          attrs: {
            "src": "/img/integrations/icon-sidebar-close.svg",
            "alt": ""
          },
          on: {
            "click": hide
          }
        })], 1)];
      }
    }, {
      key: "footer",
      fn: function (_ref2) {
        var hide = _ref2.hide;
        return [_c('div', {
          staticClass: "b-action-buttons"
        }, [!_vm.is_active ? _c('b-button', {
          staticClass: "cursor",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.addToken
          }
        }, [_vm._v(" Подключить ")]) : _vm._e(), !_vm.is_active ? _c('b-button', {
          staticClass: "cursor ml-3",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": hide
          }
        }, [_vm._v(" Отмена ")]) : _vm._e()], 1)];
      }
    }])
  }, [_c('div', {
    staticClass: "sidebar-block info"
  }, [_c('p', [_vm._v(" Профессиональное программное обеспечение для автоматизации рабочих мест кассира предприятий розничной торговли и общественного питания любых форматов и размеров ")]), _c('div', {
    staticClass: "info__detail"
  }, [_c('img', {
    attrs: {
      "src": "/img/integrations/icon-info-blue.svg",
      "alt": ""
    }
  }), _c('router-link', {
    attrs: {
      "to": "#"
    }
  }, [_vm._v("Подробная справка")]), _vm._v(" по интеграции ")], 1)]), false ? [_c('div', {
    staticClass: "error-block"
  }, [_c('img', {
    attrs: {
      "src": "/img/integrations/frontol/frontol-attention.svg",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "error-block__text"
  }, [_vm._v("Ошибка")]), _c('p', {
    staticClass: "error-block__description"
  }, [_vm._v(" Программа интеграции для обмена данными не установлена, "), _c('a', {
    staticClass: "error-block__link"
  }, [_vm._v("скачайте,")]), _vm._v(" установите и попробуйте заново. ")])])] : _vm._e(), _vm.is_active ? [_c('div', {
    staticClass: "finish-intigration-block"
  }, [_c('img', {
    attrs: {
      "src": "/img/integrations/frontol/frontol-ok.svg",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "finish-intigration-block__text"
  }, [_vm._v("Интеграция успешно подключена")]), _c('b-button', {
    staticClass: "cursor finish-intigration-block__button",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.toDevices
    }
  }, [_vm._v(" К списку касс для настройки обмена ")])], 1)] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }