<template>
  <div class="mb-2">
    <div class="d-flex align-items-center">
      <div class="step-info">{{ step }}</div>
      <div class="step-info-text">{{ title }}</div>
    </div>
    <div class="d-flex align-items-stretch mt-2">
      <div
        style="width: 32px; margin-right: 12px"
        class="d-flex align-items-center justify-content-center"
      >
        <div
          v-if="!last"
          class="sidebar-steps-stick"
        ></div>
      </div>
      <div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Steps',
    props: {
      step: String,
      title: String,
      last: Boolean
    }
  }
</script>

<style lang="scss" scoped>
  .sidebar-steps-stick {
    width: 2px;
    height: 100%;
    min-height: 16px;
    background: #00a3ff;
  }

  .step-info {
    border: 2px solid #00a3ff;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #313131;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 99px;
    margin-right: 12px;
  }

  .step-info-text {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;

    /* Text/primary - gray700 */

    color: #313131;
  }
</style>
