var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "bixolon-inner"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "bixolon-sidebar",
      "title": "Bixolon",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref) {
        var hide = _ref.hide;
        return [_vm._v(" Bixolon "), _c('div', {
          staticClass: "header-left-block"
        }, [_vm.is_active ? _c('b-button', {
          staticClass: "cursor header-left-block__button",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.turnOffIntegration
          }
        }, [_vm._v(" Выключить интеграцию ")]) : _vm._e(), _c('img', {
          staticClass: "close-sidebar cursor",
          attrs: {
            "src": "/img/integrations/icon-sidebar-close.svg",
            "alt": ""
          },
          on: {
            "click": hide
          }
        })], 1)];
      }
    }, {
      key: "footer",
      fn: function (_ref2) {
        var hide = _ref2.hide;
        return [_c('div', {
          staticClass: "b-action-buttons"
        }, [!_vm.is_active ? _c('b-button', {
          staticClass: "cursor",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.setIntegration();
            }
          }
        }, [_vm._v(" Продключить ")]) : _vm._e(), !_vm.is_active ? _c('b-button', {
          staticClass: "cursor ml-3",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": hide
          }
        }, [_vm._v(" Отмена ")]) : _vm._e()], 1)];
      }
    }])
  }, [[_c('div', {
    staticClass: "sidebar-block info"
  }, [_c('p', {
    staticClass: "sidebar-block__description"
  }, [_vm._v("Подключите принтер по инструкции")]), _c('div', {
    staticClass: "info__detail"
  }, [_c('img', {
    attrs: {
      "src": "/img/integrations/icon-info-blue.svg",
      "alt": ""
    }
  }), _c('a', {
    attrs: {
      "target": "_blank"
    }
  }, [_vm._v("Подробная справка")]), _vm._v(" по подключению принтера ")])])], _vm.errorIntegration ? [_c('div', {
    staticClass: "error-block"
  }, [_c('img', {
    attrs: {
      "src": "/img/integrations/bixolon_attention.svg",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "error-block__text"
  }, [_vm._v("Ошибка")]), _c('p', {
    staticClass: "error-block__description"
  }, [_vm._v(" Программа интеграции для обмена данными не установлена, "), _c('a', {
    staticClass: "error-block__link"
  }, [_vm._v("скачайте,")]), _vm._v(" установите и попробуйте заново. ")])])] : _vm._e(), _vm.is_active ? [_c('div', {
    staticClass: "finish-intigration-block"
  }, [_c('img', {
    attrs: {
      "src": "/img/integrations/bixolon-ok.svg",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "finish-intigration-block__text"
  }, [_vm._v("Интеграция успешно подключена")]), _c('b-button', {
    staticClass: "cursor finish-intigration-block__button",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.toDevices
    }
  }, [_vm._v(" Перейти к списку принтеров ")])], 1)] : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }