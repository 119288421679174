<template>
  <section id="sbis-inner">
    <b-sidebar
      id="sbis-sidebar"
      title="СБИС ЭДО"
      right
      backdrop
      shadow
      @shown="getCerts"
    >
      <template #header="{ hide }">
        <h2 class="head-title">СБИС ЭДО</h2>
        <img
          class="close-sidebar cursor"
          src="/img/integrations/icon-sidebar-close.svg"
          alt=""
          @click="hide"
        />
      </template>

      <div class="sidebar-block info">
        <p>
          Система СБИС обеспечивает эффективный обмен электронными документами, позволяя сэкономить время и упростить
          деловую коммуникацию внутри организации и за ее пределами.
        </p>
        <div class="info__detail">
          <img
            src="/img/integrations/icon-info-blue.svg"
            alt=""
          />
          <router-link to="#">Подробная справка</router-link>
          по интеграции
        </div>
      </div>
      <div class="sidebar-block token">
        <e-select
          v-model="entity"
          :error="entityError"
          select_name="entity"
          class="multiselect"
          placeholder="Выберите юр. лицо"
          label="Юр. лицо"
          :options="entities ?? []"
        />
        <div
          class="text"
          style="padding-top: 20px"
        >
          Логин СБИС
        </div>
        <e-input
          v-model="login"
          :error="loginError"
          error_text="Логин обязательное поле"
          placeholder="Введите логин"
        />

        <div
          style="margin-top: 22px"
          class="text"
        >
          Пароль СБИС
        </div>
        <e-input
          v-model="password"
          :search-icon="false"
          :error="passwordError"
          error_text="Пароль обязательное поле"
          type="password"
          placeholder="Введите пароль"
          @change="(val) => (this.password = val)"
        />
        <e-select
          v-model="selectedCert"
          class="multiselect"
          placeholder="Сертификат"
          label="Сертификат"
          key-id="thumbprint"
          select_name="cert"
          :options="certs ?? []"
        >
          <template #custom_name="{ item }">
            <div style="display: flex; flex-direction: column; justify-content: flex-start">
              <span
                class="mb-3 text-wrap"
                style="text-align: initial !important; font-size: 14px; line-height: 15px"
              >
                {{ item.subjectName }}
              </span>
              <span
                style="text-align: start"
                :style="new Date(item.validTo) < new Date() ? { color: '#E53835' } : { color: '#00CB91' }"
              >
                Срок действия: {{ formatDate(item.validTo) }}
              </span>
            </div>
          </template>
        </e-select>
      </div>

      <template #footer="{ hide }">
        <div class="b-action-buttons">
          <b-button
            variant="primary"
            class="cursor"
            @click="updateIntegration(hide)"
          >
            {{ data_sbis.login && data_sbis.password ? 'Обновить' : 'Подключить' }}
          </b-button>
          <b-button
            variant="outline-primary"
            class="cursor ml-3"
            @click="hide"
          >
            Отмена
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
  import { getUserCertificates } from 'crypto-pro'

  export default {
    name: 'SbisSidebar',
    apollo: {
      Entities: {
        query: require('../../analytics/gql/getEntities.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.entities = data?.Entities
          if (this.entityId) this.entity = [this.entities.find((obj) => obj.id === this.entityId)]
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },
    props: {
      is_active: Boolean,
      data_sbis: {
        type: Object
      },
      integration: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        entityError: false,
        loginError: false,
        passwordError: false,
        entities: [],
        login: '',
        selectedCert: [],
        password: '',
        entity: [],
        entityId: '',
        certs: [],
        thumbprint: ''
      }
    },

    watch: {
      entity() {
        if (this.entityError) {
          this.entityError = false
        }
      },
      login() {
        if (this.loginError) {
          this.loginError = false
        }
      },
      password() {
        if (this.passwordError) {
          this.passwordError = false
        }
      },
      'data_sbis.entity'() {
        if (this.data_sbis?.entity?.id) this.entity = [this.data_sbis.entity]
      },
      'data_sbis.login'() {
        if (this.data_sbis?.login) this.login = this.data_sbis.login
      },
      'data_sbis.password'() {
        if (this.data_sbis?.password) this.password = this.data_sbis.password
      },
      'data_sbis.thumbprint'() {
        if (this.data_sbis?.thumbprint)
          this.selectedCert = [{ name: this?.entity[0]?.name, thumbprint: this?.data_sbis?.thumbprint }]
      },
      async selectedCert() {
        const req = this.getCertsData()
        if (!req?.inn) return
        const { data } = await this.$apollo.query({
          query: require('../gql/EntityByReq.graphql'),
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              inn: req.inn,
              ogrn: req.ogrn
            }
          }
        })
        if (data?.EntityByRequisites) {
          this.entity = [data?.EntityByRequisites]
        }
      }
    },

    mounted() {
      if (this.data_sbis.entity?.id) this.entity = [this.data_sbis.entity]
      if (this.data_sbis.login) this.login = this.data_sbis.login
      if (this.data_sbis.password) this.password = this.data_sbis.password
      if (this.data_sbis.thumbprint)
        this.selectedCert = [{ name: this?.entity[0]?.name, thumbprint: this?.data_sbis?.thumbprint }]
    },

    methods: {
      async createSignature() {
        try {
          let operation =
            this.data_sbis.login && this.data_sbis.password && this.data_sbis.thumbprint
              ? 'UpdateSignature'
              : 'CreateSignature'
          if (operation === 'CreateSignature' && this.selectedCert.length === 0) return
          if (operation === 'UpdateSignature' && this.data_sbis.thumbprint === this.selectedCert[0].thumbprint) return
          await this.$apollo.query({
            query: require(`../gql/${operation}.graphql`),
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
            variables: {
              input: {
                entity: this.entity[0].id,
                thumbprint: this.selectedCert[0].thumbprint ?? '',
                date_begin: this.selectedCert[0].validFrom,
                date_end: this.selectedCert[0].validTo
              }
            }
          })
        } catch (e) {
          return this.$noty.error('Ошибка при создании или обновлении сертификата')
        }
      },
      async getCerts() {
        this.certs = await getUserCertificates()
        this.certs = this.certs.map((el) => {
          return {
            ...el,
            label: el.subjectName
          }
        })
      },
      async copy(info) {
        await navigator.clipboard.writeText(info)
        this.$noty.show(`Скопированно`)
      },
      async updateIntegration(hide) {
        if (!this.entity?.[0]) {
          this.entityError = true
          return this.$noty.error('Выберите юр. лицо')
        }
        if (!this.login) {
          this.loginError = true
          return this.$noty.error('Введите логин')
        }
        if (!this.password) {
          this.passwordError = true
          return this.$noty.error('Введите пароль')
        }
        if (this.selectedCert[0]?.thumbprint) {
          const entity = this.entities.find((obj) => obj.id === this.entity?.[0]?.id)
          const inn = this.getCertsData().inn
          if (entity.inn !== inn) {
            return this.$noty.error('ИНН юр. лица сертификата и выбранного юр. лица не совпадают')
          }
        }
        let operation = this.data_sbis.login && this.data_sbis.password ? 'UpdateUsrInt' : 'CreateUsrInt'
        try {
          const { data } = await this.$apollo.mutate({
            mutation: require(`../gql/${operation}.graphql`),
            variables: {
              input: {
                type: 'sbis',
                login: this.login,
                password: this.password,
                entity: this.entity[0].id
              }
            }
          })
          if (!data?.[operation]?.successful) {
            await this.$emit('refetch')
            return this.$noty.error('Неверный логин / пароль')
          }
          await this.createSignature()
          await this.$emit('refetch')
          if (operation === 'UpdateUsrInt') {
            this.$noty.show('Интеграция СБИС успешно обновлена')
          } else {
            this.$noty.show('Интеграция СБИС успешно подключена')
          }
          hide()
        } catch (e) {
          let operation = this.data_sbis.login && this.data_sbis.password ? 'обновлении' : 'подключении'
          return this.$noty.error(`Ошибка при ${operation} интеграции`)
        }
      },
      getCertsData() {
        const arr = this.selectedCert?.[0]?.subjectName?.split(',')
        const data = { inn: '', ogrn: '', thumbprint: this.selectedCert[0]?.thumbprint ?? '' }
        for (let i = 0; i < arr?.length; i++) {
          if (arr[i].includes('ИНН')) {
            data.inn = arr[i].replace(/[^0-9]/g, '')
          }
          if (arr[i].includes('ОГРН')) {
            data.ogrn = arr[i].replace(/[^0-9]/g, '')
          }
        }
        return data
      },
      formatDate(date) {
        return new Intl.DateTimeFormat('ru-RU').format(new Date(date))
      },
      clearEntity() {
        this.selectedEntity = ''
      },
      clearBranch() {
        this.selectedBranch = ''
      }
    }
  }
</script>

<style lang="scss" scoped>
  .multiselect {
    margin-top: 22px;
    //height: 65px;
  }

  ::v-deep p.label {
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    color: #313131 !important;
    margin-bottom: 6px !important;
  }

  ::v-deep .ms {
    height: 42px !important;

    input {
      height: 39px !important;
    }

    .search {
      height: 100% !important;
    }
  }

  #sbis-inner {
    ::v-deep #sbis-sidebar {
      width: 680px !important;
      .b-sidebar-header {
        .head-title {
          font-family: 'Gilroy-Medium', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 28px;
          line-height: 33px;
          color: #000000;
        }

        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

        img.close-sidebar {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 22px;
        }
      }

      .b-sidebar-footer {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 65px;
        display: flex;

        .b-action-buttons {
          padding: 0 22px 0;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .b-sidebar-body {
        background: #fff;
        padding: 0;

        .sidebar-block {
          //border-bottom: 1px solid #e1e1e1;
          padding: 22px;

          .block-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 16px;
            color: #313131;
            margin-bottom: 16px;
          }

          p {
            font-size: 16px;
            line-height: 24px;
            color: #000000;
          }

          a {
            border-bottom: 1px solid #0066ff;
            font-size: 16px;
          }
        }

        .info {
          font-size: 16px;
          line-height: 24px;
          color: #000000;

          &__detail {
            background: #e6f0ff;
            border-radius: 4px;
            margin-top: 22px;
            border-left: 3px solid #b9d6ff;
            padding: 15px;

            img {
              margin-right: 15px;
            }

            a {
              color: #313131;
              font-weight: 600;
              border-bottom: 1px solid #313131;
            }
          }
        }

        .token {
          .input {
            height: 42px !important;
            border: 1px solid #bcbcbc;
            border-radius: 2px !important;
            color: #313131;
            padding: 0px 12px 0px 10px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px !important;
            line-height: 14px !important;

            &::placeholder {
              color: #bcbcbc;
            }
          }

          .text {
            margin-top: 22px;
            margin-bottom: 6px;

            img {
              margin-left: 5px;
            }
          }

          form {
            margin-top: 7px;
          }
        }

        .b-select {
          border: 1px solid #e1e1e1;
          border-radius: 4px;

          &__title {
            border-bottom: 1px solid #e1e1e1;
            display: flex;

            .item {
              width: 50%;
              padding: 8px 0;
              text-align: center;

              &:nth-child(2) {
                border-left: 1px solid #e1e1e1;
              }
            }
          }

          &__item {
            display: flex;
            padding: 16px;
            justify-content: space-between;

            input,
            .ivu-select {
              width: 282px;
            }
          }
        }
      }
    }
  }
  ::v-deep .input-block .input.error {
    background: var(--red50);
    border: 1px solid var(--red400) !important;
  }
  ::v-deep .error_text {
    color: var(--red400) !important;
    font-family: 'Gilroy-Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    margin-top: 6px;
  }
</style>
