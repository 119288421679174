<template>
  <section id="dklink-inner">
    <b-sidebar
      id="dklink-sidebar"
      title="Честный знак"
      right
      backdrop
      shadow
    >
      <template #header="{ hide }">
        DKLink FO
        <img
          class="close-sidebar cursor"
          src="/img/integrations/icon-sidebar-close.svg"
          alt=""
          @click="hide"
        />
      </template>

      <div class="sidebar-block info">
        <p>
          Кассовая программа DKLINK Front Office обеспечит удобство работы как розничного магазина, так и целой сети.
          Удобная программа учета на кассе может интегрироваться с мобильными решениями.
        </p>
        <div class="info__detail">
          <img
            src="/img/integrations/icon-info-blue.svg"
            alt=""
          />
          <router-link to="#">Подробная справка</router-link>
          по интеграции
        </div>
      </div>
      <div class="sidebar-block token">
        <div class="text mb-2">
          <!--          <img src="/img/integrations/icon-info-gray.svg" alt="" />-->
          <!--          <b-form @submit.prevent="addToken">-->
          <!--            <b-form-input-->
          <!--              v-model="token"-->
          <!--              type="text"-->
          <!--              placeholder="Номер токена"-->
          <!--            />-->
          <!--          </b-form>-->
        </div>
        <div style="font-style: normal; font-weight: 500; font-size: 18px; line-height: 16px; color: #313131">
          Синхронизируйте Энтерсайт с DKLink
        </div>
        <div
          style="
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin-top: 16px;
            color: #000000;
            /* or 150% */
          "
        >
          Чтобы завершить интеграцию вставьте токен и идентификатор компании в настройки интеграции DKLink FO
        </div>

        <div class="text mb-2">Токен</div>
        <b-input-group class="mt-3">
          <template #append>
            <b-input-group-text
              style="cursor: pointer"
              @click="copy(integration.token)"
            >
              <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.93359 18H3.21875C1.66789 18 0.40625 16.7384 0.40625 15.1875V5.66016C0.40625 4.1093 1.66789 2.84766 3.21875 2.84766H9.93359C11.4845 2.84766 12.7461 4.1093 12.7461 5.66016V15.1875C12.7461 16.7384 11.4845 18 9.93359 18ZM3.21875 4.25391C2.44339 4.25391 1.8125 4.8848 1.8125 5.66016V15.1875C1.8125 15.9629 2.44339 16.5938 3.21875 16.5938H9.93359C10.709 16.5938 11.3398 15.9629 11.3398 15.1875V5.66016C11.3398 4.8848 10.709 4.25391 9.93359 4.25391H3.21875ZM15.5586 13.4297V2.8125C15.5586 1.26164 14.297 0 12.7461 0H4.94141C4.55304 0 4.23828 0.314758 4.23828 0.703125C4.23828 1.09149 4.55304 1.40625 4.94141 1.40625H12.7461C13.5215 1.40625 14.1523 2.03714 14.1523 2.8125V13.4297C14.1523 13.8181 14.4671 14.1328 14.8555 14.1328C15.2438 14.1328 15.5586 13.8181 15.5586 13.4297Z"
                  fill="#313131"
                />
              </svg>
            </b-input-group-text>
          </template>
          <b-form-input
            style="max-width: 630px"
            disabled
            :value="integration.token"
          />
        </b-input-group>

        <div class="text mb-2">Идентификатор компании</div>

        <b-input-group class="mt-3">
          <template #append>
            <b-input-group-text
              style="cursor: pointer"
              @click="copy(user.company?.id)"
            >
              <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.93359 18H3.21875C1.66789 18 0.40625 16.7384 0.40625 15.1875V5.66016C0.40625 4.1093 1.66789 2.84766 3.21875 2.84766H9.93359C11.4845 2.84766 12.7461 4.1093 12.7461 5.66016V15.1875C12.7461 16.7384 11.4845 18 9.93359 18ZM3.21875 4.25391C2.44339 4.25391 1.8125 4.8848 1.8125 5.66016V15.1875C1.8125 15.9629 2.44339 16.5938 3.21875 16.5938H9.93359C10.709 16.5938 11.3398 15.9629 11.3398 15.1875V5.66016C11.3398 4.8848 10.709 4.25391 9.93359 4.25391H3.21875ZM15.5586 13.4297V2.8125C15.5586 1.26164 14.297 0 12.7461 0H4.94141C4.55304 0 4.23828 0.314758 4.23828 0.703125C4.23828 1.09149 4.55304 1.40625 4.94141 1.40625H12.7461C13.5215 1.40625 14.1523 2.03714 14.1523 2.8125V13.4297C14.1523 13.8181 14.4671 14.1328 14.8555 14.1328C15.2438 14.1328 15.5586 13.8181 15.5586 13.4297Z"
                  fill="#313131"
                />
              </svg>
            </b-input-group-text>
          </template>
          <b-form-input
            style="max-width: 630px"
            disabled
            :value="user.company?.id"
          />
        </b-input-group>
      </div>
      <template #footer="{ hide }">
        <div class="b-action-buttons">
          <b-button
            variant="primary"
            class="cursor"
            @click="updateIntegration(hide)"
          >
            {{ integration.id ? 'Сохранить' : 'Подключить' }}
          </b-button>
          <b-button
            variant="outline-primary"
            class="cursor ml-3"
            @click="hide"
          >
            Отмена
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'CrpTech',
    props: {
      is_active: Boolean,
      integration: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        certs: [],
        selectedCert: '',
        selectedBranch: '',
        selectedEntity: '',
        token: ''
      }
    },
    async mounted() {
      const localstorage = JSON.parse(localStorage.getItem('entersight'))
      const token = localstorage ? localstorage.core.token : ''
      this.token = token
    },
    methods: {
      async copy(info) {
        await navigator.clipboard.writeText(info)
        this.$noty.show(`Скопированно`)
      },
      updateIntegration(hide) {
        if (!this.is_active)
          this.$apollo
            .mutate({
              mutation: require('../gql/InitCashIntegration.gql'),
              variables: {
                type: 'dklink'
              }
            })
            .then(() => {
              this.$noty.show(`Интеграция успешна`)
            })
            .catch((e) => {
              console.error('error: ', e)
              this.$noty.show(`Что-то пошло не так. Попробуйте еще раз`)
            })
        else hide()
      },
      formatDate(date) {
        return new Intl.DateTimeFormat('ru-RU').format(new Date(date))
      },
      clearEntity() {
        this.selectedEntity = ''
      },
      clearBranch() {
        this.selectedBranch = ''
      }
    },
    computed: {
      ...mapGetters({
        branches: 'settings/getBranchList',
        user: 'core/user'
      }),
      entities() {
        return this.branches.find((el) => el.id === this.selectedBranch)?.entities ?? []
      },
      inn_error() {
        const cert = this.certs?.find((el) => el.thumbprint === this.selectedCert)
        const entity = this.entities?.find((el) => el.id === this.selectedEntity)

        if (!cert || !entity) return true

        return !cert.subjectName.includes(entity.inn)
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    #dklink-sidebar {
      width: 680px !important;

      .b-sidebar-header {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 85px;
      }

      .b-sidebar-footer {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 65px;
        display: flex;
      }

      .b-sidebar-body {
        background: #fff;
        padding: 0;

        .sidebar-block {
          .block-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 16px;
            color: #313131;
            margin-bottom: 16px;
          }

          a {
            border-bottom: 1px solid #0066ff;
            font-size: 16px;
          }
        }

        .info {
          &__detail {
            a {
              color: #313131;
              font-weight: 600;
              border-bottom: 1px solid #313131;
            }
          }
        }

        .token {
          form {
            margin-top: 7px;

            input {
              height: 42px !important;
              border: 1px solid #bcbcbc;
              border-radius: 2px;
              font-size: 16px;
              color: #313131;
            }
          }
        }

        .b-select {
          border: 1px solid #e1e1e1;
          border-radius: 4px;

          &__title {
            border-bottom: 1px solid #e1e1e1;
            display: flex;

            .item {
              width: 50%;
              padding: 8px 0;
              text-align: center;

              &:nth-child(2) {
                border-left: 1px solid #e1e1e1;
              }
            }
          }

          &__item {
            display: flex;
            padding: 16px;
            justify-content: space-between;

            input,
            .ivu-select {
              width: 282px;
            }
          }
        }
      }
    }
  }

  #dklink-inner {
    #dklink-sidebar {
      width: 680px !important;

      .b-sidebar-header {
        img.close-sidebar {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 22px;
        }
      }

      .b-sidebar-footer {
        .b-action-buttons {
          padding: 0 22px 0;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .b-sidebar-body {
        background: #fff;
        padding: 0;

        .sidebar-block {
          border-bottom: 1px solid #e1e1e1;
          padding: 22px;

          p {
            font-size: 16px;
            line-height: 24px;
            color: #000000;
          }
        }

        .info {
          font-size: 16px;
          line-height: 24px;
          color: #000000;

          &__detail {
            background: #e6f0ff;
            border-radius: 4px;
            margin-top: 22px;
            border-left: 3px solid #b9d6ff;
            padding: 15px;

            img {
              margin-right: 15px;
            }
          }
        }

        .token {
          .text {
            margin-top: 16px;

            img {
              margin-left: 5px;
            }
          }
        }

        .b-select {
          &__title {
            .item {
              &:nth-child(2) {
                border-left: 1px solid #e1e1e1;
              }
            }
          }
        }
      }
    }
  }
</style>
